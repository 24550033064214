import {createRouter, createWebHashHistory, createWebHistory, createMemoryHistory} from 'vue-router'
import Home from '../views/Home.vue'
import Model from '../views/00_Model.vue'
//import Help from "../views/Help";

const routes = [
    // Einstieg (Ladebildschirm)
    {
        path: '/',
        component: Home,
        meta: {
            title: 'Start'
        }
    },
    // Hilfe
    // {
    //     path: '/help',
    //     component: Help
    //     //component: () => import(/* webpackChunkName: "help" */ '../views/Help.vue')
    // },

    // 3D Modell
    {
        path: '/model',
        component: Model,
        meta: {
            title: 'Model'
        }
    },
    // 3D Modell mit TextBanner
    {
        path: '/TextBanner',
        component: () => import(/* webpackChunkName: "textbanner" */ '../views/01_Hotspots.vue'),
        meta: {
            title: 'Text Banner'
        }
    },
    // 3D Modell mit TextBanner und Hotspot
    {
        path: '/inside',
        component: () => import(/* webpackChunkName: "infobox" */ '../views/02_Inside.vue')
    },
    // 3D Modell mit TextBanner und HotspotComparison
    {
        path: '/kitchen',
        component: () => import(/* webpackChunkName: "infoboxcomparison" */ '../views/03_Kitchen.vue')
    },
    //
    // // 3D Modell mit TextBanner und HotspotVideo
    // {
    //     path: '/InfoBoxVideo',
    //     component: () => import(/* webpackChunkName: "infoboxvideo" */ '../views/04_InfoBoxVideo.vue')
    // },
    //
    // // 3D Modell mit Animationsstart über onReady
    // {
    //     path: '/Animation',
    //     component: () => import(/* webpackChunkName: "animation" */ '../views/05_onReady.vue')
    // },
    //
    // // 3D Modell mit Audioloop
    // {
    //     path: '/Audio',
    //     component: () => import(/* webpackChunkName: "audio" */ '../views/06_AudioController.vue')
    // },
    // // Billboard Mode
    // {
    //     path: '/billboard',
    //     component: () => import(/* webpackChunkName: "billboard" */ '../views/07_Billboard.vue'),
    //     meta: {
    //         title: 'Billboard Mode'
    //     }
    // },
    // // Hotspots
    // {
    //     path: '/hotspots',
    //     component: () => import(/* webpackChunkName: "hotspots" */ '../views/08_Hotspots.vue'),
    //     meta: {
    //         title: 'Hotspots'
    //     }
    // },
    // // Overlay
    // {
    //     path: '/overlay',
    //     component: () => import(/* webpackChunkName: "overlay" */ '../views/09_Overlay.vue'),
    //     meta: {
    //         title: 'Overlay'
    //     }
    // },
    // Credits
    {
        path: '/Credits',
        component: () => import(/* webpackChunkName: "credits" */ '../views/Credits.vue'),
        meta: {
            title: 'Credits'
        }
    },

];

function getHistory()
{
    if (process.env.VUE_APP_ROUTER_MODE === 'hash')
    {
        return createWebHashHistory();
    }

    if (process.env.VUE_APP_ROUTER_MODE === 'history')
    {
        return createWebHistory();
    }

    return createMemoryHistory();
}

const router = createRouter({
    history: getHistory(),
    base: process.env.BASE_URL,
    routes
});

export default router
