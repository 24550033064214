<template>
    <div></div>
</template>

<script>
    export default {
        name: "PageNotFound",
        created: function() {
            // Redirect outside the app using plain old javascript
            window.location.href = (process.env.BASE_URL ? process.env.BASE_URL : '');
        }
    }
</script>