<template>
    <div id="app" v-bind:class="getClasses">
        <ZdfHeader></ZdfHeader>
        <div class="content">
            <router-view v-slot="{ Component }">
                <transition name="fade" mode="out-in">
                    <component :is="Component" />
                </transition>
            </router-view>
            <Help></Help>
            <FullscreenContainer></FullscreenContainer>
            <FullscreenComparison></FullscreenComparison>
            <StateDebugger v-if="debug"></StateDebugger>
            <ChildAreaProtection></ChildAreaProtection>
        </div>
        <ZdfFooter></ZdfFooter>
    </div>
</template>

<script>
    export default {
        name: "App",
        inject: ['$eventBus', '$tracking'],
        data: function()
        {
            return {
                scale: 1,

                backdrop: false,
                showHelp: false,
                babylonLoaded: false,
                muted: true,
                home: true,
                environment: '',
                debug: false,
                first: false,
            };
        },
        computed: {
            getClasses()
            {
                let classes = {'is-home' : this.home,
                    'is-muted': this.muted,
                    'has-backdrop': this.backdrop,
                    'help-visible': this.showHelp,
                    'babylon-loaded': this.babylonLoaded,
                    'is-first': this.first,
                };

                if (this.$tracking.environment)
                {
                    classes['env-' + this.$tracking.environment] = true;
                }

                return classes;
            }
        },
        created()
        {
            // Immer auf erster Seite starten
            /*
            if (this.$route.path !== '/')
            {
                //this.$router.push( '/' );
            }
            */
            // Babylon State Debugger anzeigen
            this.debug = process.env.VUE_APP_STATE_DEBUGGER === '1';
            this.$eventBus.$on('debug', () => {
                this.debug = true;
            });

            // Hilfe ein-/ausblenden
            this.$eventBus.$on('help.toggle', () => {
                this.showHelp = !this.showHelp;
            });
            // toggle Audio on/off
            this.$eventBus.$on('audio.toggle', () => {
                this.muted = !this.muted;
                //console.log('audio.toggle, is-muted: ', this.muted);
            });

            // is home?
            this.$eventBus.$on('currentPage', (data) => {
                this.home = (data === 0);

                // bei Seitenwechsel Buttons zurücksetzen
                this.showHelp = false;
            });

            // first view
            this.$eventBus.$on('currentPage', (data) => {
                this.first = (data === 1);
            });

        },
        mounted() {

        },
        // Wenn Babylon geladen ist...
        babylonReady: function () {
            this.babylonLoaded = true;
            window.babylonController = this.$babylonController;
        }
    };
</script>

<style lang="scss">

    @import "./BabylonStory/assets/scss/base";

</style>
