<template>
    <div>
        <div class="toggle text-orange m-3" v-on:click="showDebugger = !showDebugger">
            <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z" class=""></path></svg>
        </div>
        <div class="stateDebugger" v-bind:class="{ 'd-none': !showDebugger }">
            <div id="editor">
                <label style="color: white;font-size: 1.0rem;">
                    <input type="checkbox" name="fullstate" v-model="showFullState" value="1"> Show Full State
                </label>
                <textarea v-model="textState" v-bind:readonly="fullState"></textarea>
                <div ref="errorMessage" class="error"></div>
            </div>
            <div id="dragbar"></div>
        </div>
    </div>
</template>

<script>
    import jsonBeautify from 'json-beautify';
    export default {
        name: "StateDebugger",
        data: function (){
            return {
                state: {},
                fullState: {},
                showFullState: false,
                showDebugger: false,
            };
        },
        computed: {
            textState: {
                get()
                {
                    if (this.showFullState)
                    {
                        return jsonBeautify( this.fullState, null, 2, 40 );
                    }
                    return jsonBeautify( this.state, null, 2, 80 );
                },
                set(val)
                {
                    if (this.fullState)
                    {
                        return;
                    }
                    try {
                        this.state = JSON.parse(val);
                        this.$babylonController.setState(this.state);
                        this.$refs.errorMessage.innerText = '';
                    }
                    catch (e)
                    {
                        this.$refs.errorMessage.innerText = e.toString().replace('SyntaxError: JSON.parse: ', '');
                    }
                }
            }
        },
        created()
        {
            this.$eventBus.$on('BABYLON.newState', () => {
                this.state = this.$babylonController.currentState;
                this.fullState = this.$babylonController.currentFullState;
            });
        },
        mounted() {

            let stateDebugger = document.querySelector('.stateDebugger');

            function handleMouseDown(e) {

                stateDebugger.style.width = ( e.pageX + 2.5 ) + 'px';
            }

            let dragbar = document.querySelector('#dragbar');
            if (dragbar)
            {
                dragbar.addEventListener('mousedown', () => {
                    document.addEventListener('mousemove', handleMouseDown)
                });
            }

            document.addEventListener('mouseup', () => {

                document.removeEventListener('mousemove', handleMouseDown);
            })

        }
    }



</script>

<style lang="scss" scoped>

    .stateDebugger {

        user-select: auto;
        pointer-events: auto;

        background: $blue-gray;

        display: flex;
        #editor {
            flex: 1 1 auto;
            overflow: hidden;
        }
        #dragbar{
            background: black;
            height:100%;
            flex: 0 0 6px;
            cursor: col-resize;
            &:hover {
                background: $orange;
            }
        }

        position: fixed;
        z-index: map_get($z-layers, stateDebugger);
        top: 0;

        width: 20vw;
        height: 100vh;

        box-shadow: 1px 0 5px rgba(0, 0, 0, 0.35);

        .error {
            color: red;
        }



        textarea {

            width: 100%;
            height: 100%;

            background: none;
            color: white;
            font-family: monospace;
            font-size: 0.85rem;
            border: 0;
            white-space: pre;
            padding: 1rem;

            resize: none;
            overflow: hidden;
        }
    }

    .toggle {
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: map_get($z-layers, stateDebugger) + 1;
        pointer-events: auto;
        cursor: pointer;
    }


</style>
