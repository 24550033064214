import { DefaultLoadingScreen } from  "@babylonjs/core/Loading/loadingScreen"
import { Engine } from "@babylonjs/core/Engines/engine";
import { Scene } from "@babylonjs/core/scene";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { Color3 } from "@babylonjs/core/Maths/math.color";
import { Color4 } from "@babylonjs/core/Maths/math.color";
import { HemisphericLight } from "@babylonjs/core/Lights/hemisphericLight";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { Texture } from "@babylonjs/core/Materials/Textures/texture";
import { PBRMetallicRoughnessMaterial } from "@babylonjs/core/Materials/PBR/pbrMetallicRoughnessMaterial";
import {CubeTexture} from "@babylonjs/core/Materials/Textures/cubeTexture";
//import {HDRCubeTexture} from "@babylonjs/core/Materials/Textures/hdrCubeTexture";

import '@babylonjs/core/Helpers/sceneHelpers';

import '@babylonjs/loaders/glTF/';

import BabylonObject from "./objects/BabylonObject.js";
import BabylonHotspot from "./objects/BabylonHotspot.js";
import Label from "./objects/Label.js";
import babylonDefaultConfig from "./babylonDefaultConfig.js";
import EventBus from "../tools/event-bus";
import mergeConfig from "../tools/mergeConfig";
import hasOwnProperty from "../tools/hasOwnProperty";
import Camera from "./objects/Camera";
import TimerManager from "@/BabylonStory/tools/TimerManager";
import ForEach from '../tools/ForEach';

// https://doc.babylonjs.com/extensions/configuring_the_viewer
// https://doc.babylonjs.com/extensions/the_templating_system
export default class BabylonController
{
    constructor()
    {
        this.ready = false;
        this.currentState = {};
        this.currentFullState = {};
    }
    init(config)
    {
        // Babylon Config und Standardwerte zusammenführen
        config.babylon = mergeConfig(babylonDefaultConfig, config.babylon);
        this.config = config;

        let self = this;
        this.objects = {};
        this.hotspots = {};

        // Timer Manager
        this.timer = new TimerManager();

        /// Global Variables
        this.lightIntensity = this.config.babylon.lightIntensity;
        this.minCamRadius = this.config.babylon.minCamRadius;
        this.maxCamRadius = this.config.babylon.maxCamRadius;
        this.panSensibility = this.config.babylon.panSensibility;

        // Babylon Lade Seite überschreiben mit leerer Funktion (wir zeigen dafür die vue.js Ansicht)
        DefaultLoadingScreen.prototype.displayLoadingUI = function ()
        {
        };

        // Lade Seite schließen => Babylon Ready
        DefaultLoadingScreen.prototype.hideLoadingUI = function ()
        {
            self.ready = true;
            EventBus.$emit('BABYLON.Loaded');
        };

        // Get the 3D canvas element
        let canvas = document.getElementById("renderCanvas");
        this.canvas = canvas;

        // Generate the BABYLON 3D engine
        let engine = new Engine(canvas, true);
        this.engine = engine;

        /******* Add the create scene function ******/
        let createScene = function ()
        {

            // Create the scene space

            engine.displayLoadingUI();

            let scene = new Scene(engine);
            self.scene = scene;

            // Debug
            //scene.debugLayer.show();

            // self.createLoadingScreenV2(scene);

            // Hintergrund: Blau Grau
            if (config.theme === 'dark')
            {
                scene.clearColor = new Color4(45/255, 55/255, 70/255);
            }
            else if (config.theme === 'light')
            {
                scene.clearColor = new Color4(224/255, 223/255, 222/255);
            }
            else
            {
                // eslint-disable-next-line no-console
                console.error('Theme existiert nicht: ', config.theme);
            }

            // Add lights to the scene
            let hemisphericLight = new HemisphericLight("HemisphericLight", new Vector3(1, 1, 0), scene);
            hemisphericLight.intensity = self.lightIntensity;


            // HDR Umgebung anschalten und eigene HDR oder .env Textur verlinken  // Zeile 11 bzw. 12 müssen dazu aktiviert sein

            scene.createDefaultEnvironment({
                createSkybox: false,
                skyBoxSize: 10000000,
                createGround: false,
                //groundYBias: 5,
            });

            let img_path = process.env.VUE_APP_FOLDER + (process.env.VUE_APP_FOLDER.slice(-1) === '/' ? '' : '/') + "img/";
            scene.environmentTexture = new CubeTexture(img_path + "environmentSpecular.env", scene);


            self.loadObjects();

            return scene;
        };


        /******* End of the create scene function ******/

        let scene = createScene(); //Call the createScene function

        // /// Scene optimizer
        // let options = new BABYLON.SceneOptimizerOptions(30, 2000);
        // options.addOptimization(new BABYLON.HardwareScalingOptimization(0, 1));
        // options.addOptimization(new BABYLON.HardwareScalingOptimization(0, 1.5));
        // options.addOptimization(new BABYLON.HardwareScalingOptimization(scene, options));
        // // Optimizer
        // new BABYLON.SceneOptimizer(scene, options);

        // BABYLON.SceneOptimizerOptions.HighDegradationAllowed();

        // Register a render loop to repeatedly render the scene
        engine.runRenderLoop(function ()
        {
            scene.render();
        });

        // Watch for browser/canvas resize events
        window.addEventListener("resize", function ()
        {
            engine.resize();
        });

        // Debug Funktionen erzeugen
        if (process.env.NODE_ENV === 'development')
        {
            this.createDebugger();
        }
    }

    // Ersetzt durch Vue.mixin "mounted" in BabylonStory.js
    onReady(fct)
    {
        if (this.ready)
        {
            fct();
        }
        else
        {
            EventBus.$on('BABYLON.Loaded', fct);
        }
    }

    addObject(obj)
    {
        if (!this.objects[obj.name] && !this[obj.name])
        {
            this.objects[obj.name] = obj;
            this[obj.name] = obj;
        }
        else if (process.env.NODE_ENV === 'development')
        {
            // eslint-disable-next-line no-console
            console.error('BabylonStory.addObject(): Objekt Name ist bereits belegt:', obj.name);
        }
    }

    loadObjects()
    {
        // --------------------------------------------------------------------------------
        //  Kamera erstellen
        // --------------------------------------------------------------------------------
        this.addObject(new Camera(this.scene, this.canvas, 'camera', this.config.babylon.camera));

        // --------------------------------------------------------------------------------
        //  Template Objekte laden
        // --------------------------------------------------------------------------------
        let objects = this.config.objects;
        let promises = [];
        for (let key in Object.keys(objects))
        {
            let babylonObject = new BabylonObject(
                this.scene,
                objects[key].filename,
                objects[key].name,
                objects[key].options
            );
            if (typeof objects[key].callback === "function")
            {
                babylonObject.callback = objects[key].callback;
            }
            this.addObject(babylonObject);
            promises.push(babylonObject.getPromise());
        }
        Promise.all(promises).then(() => {
            this.engine.hideLoadingUI();
        });

        // --------------------------------------------------------------------------------
        //	Hotspot
        // --------------------------------------------------------------------------------
        this.addObject(new BabylonHotspot(
            this,
            'hotspot',
            {
                trigger: true,
                visible: false,
                scale: 1,
                //material: this.scene.getMaterialByID('hotspotStandardMaterial'),
                position: [0, 6, -2],
            }
        ));

        // --------------------------------------------------------------------------------
        //	Label / Text
        // --------------------------------------------------------------------------------
        this.addObject(new Label(
            this.scene,
            "label",
            {
                visible: false,
                text: 'Notre Dame',
                position: [0, 9, 0],
            }
        ));

    }

    // --------------------------------------------------------------------------------
    //  Debug
    // --------------------------------------------------------------------------------

    showDebug()
    {
        this.scene.debugLayer.show();
    }

    createDebugger()
    {
        import(/* webpackChunkName: "Debugger" */ "./Debugger").then((Inspector) =>
        {
            Inspector.default(this);
        });
    }

    // --------------------------------------------------------------------------------
    //  Animation
    // --------------------------------------------------------------------------------

    startAnimation(animation, loop, speedratio)
    {
        let aGroup = this.scene.getAnimationGroupByName(animation);
        if (!aGroup)
        {
            // eslint-disable-next-line no-console
            console.error('Animation group not found:', animation);
        }
        else
        {
            aGroup.start(loop, speedratio);
        }
    }

      goToAnimation(animation, frame)
    {
        let aGroup = this.scene.getAnimationGroupByName(animation);
        if (!aGroup)
        {
            // eslint-disable-next-line no-console
            console.error('Animation group not found:', animation);
        }
        else
        {
            aGroup.start();
            aGroup.goToFrame(frame);
            aGroup.stop()
        }
    }

    // --------------------------------------------------------------------------------
    //  babyloneState
    // --------------------------------------------------------------------------------
    setState(newState)
    {
        this.timer.reset();
        this.currentState = Object.assign({}, newState);

        // Objekte ansteuern
        for (let obj in this.objects)
        {
            if (hasOwnProperty(this.objects, obj))
            {
                newState[obj] = this.objects[obj].setState(newState[obj]);
            }
        }

        // alle Hotspots löschen
        ForEach(this.hotspots, (hotspot) =>
        {
            hotspot.dispose();
        });
        this.hotspots = {};
        // Hotspots
        if (newState.hotspots)
        {
            ForEach(newState.hotspots, (hotspot, name) =>
            {
                if (hasOwnProperty(this.hotspots, name))
                {
                    newState.hotspots[name] = this.hotspots[name].setState( newState.hotspots[name] );
                }
                else
                {
                    this.hotspots[name] = new BabylonHotspot(
                        this,
                        'hotspot_' + name,
                        newState.hotspots[name]
                    );
                }
            });
        }

        // Befehle vom Controller verfügbar machen
        let commands = this.config.babylon.functionDefaults;
        for (let command in commands)
        {
            if (hasOwnProperty(commands, command))
            {
                // Befehl aufrufen
                if (hasOwnProperty(newState, command))
                {
                    // mit Parametern aus newState
                    this[command](...newState[command]);
                }
                else
                {
                    // mit dem Standartwert aus der Config
                    this[command](...commands[command]);
                }
            }
        }

        // Fehlende Babylon Objekte bei der Entwicklung ausgeben
        if (process.env.NODE_ENV === 'development')
        {
            let commands = Object.keys(this.config.babylon.functionDefaults);
            for (let obj in newState)
            {
                if (hasOwnProperty(newState, obj))
                {
                    // wenn kein Befehl und kein Objekt
                    if (commands.indexOf(obj) === -1 && !hasOwnProperty(this.objects, obj) && obj !== 'hotspots')
                    {
                        // eslint-disable-next-line no-console
                        console.error('Missing Babylon Object:', obj);
                    }
                }
            }
        }

        this.currentFullState = newState;
        EventBus.$emit('BABYLON.newState');
    }

    // --------------------------------------------------------------------------------
    //  Camera (deprecated)
    //  Nun als eigenes Objekt in ./objects/Camera.js
    // --------------------------------------------------------------------------------
    /**
     * @deprecated use camera.setPosition() instead
     */
    setCamera(alpha, beta, radius)
    {
        this.camera.setPosition(alpha, beta, radius);
    }

    /**
     * @deprecated use camera.setFOV() instead
     */
    setFOV(radians)
    {
        this.camera.setFOV(radians);
    }

    /**
     * @deprecated use camera.move() instead
     */
    moveCamera(alpha, beta, radius, newX, newY, newZ)
    {
        // Standard Target aus der Config laden
        if (typeof newX === 'undefined')
        {
            newX = this.config.babylon.functionDefaults.moveCamera[3];
            newY = this.config.babylon.functionDefaults.moveCamera[4];
            newZ = this.config.babylon.functionDefaults.moveCamera[5];
        }

        this.camera.move(alpha, beta, radius, newX, newY, newZ);
    }

    /**
     * @deprecated use camera.setTarget() instead
     */
    setTarget(newX, newY, newZ)
    {
        this.camera.setTarget(newX, newY, newZ);
    }

    /**
     * @deprecated use camera.setRadiusLimits() instead
     */
    setCameraRadiusLimit(min, max)
    {
        this.camera.setRadiusLimits(min, max);
    }

    /**
     * @deprecated use camera.zoom() instead
     */
    zoomCamera(radians)
    {
        this.camera.zoom(radians);
    }

    /**
     * @deprecated use camera.setAutoRotateBehaviour() instead
     */
    setAutoRotateBehaviour(bool){
        this.camera.setAutoRotateBehaviour(bool);
    }

    /**
     * @deprecated use camera.setIdleRotationSpeed() instead
     */
    setIdleRotationSpeed(number){
        this.camera.setIdleRotationSpeed(number);
    }

    /**
     * @deprecated use camera.setClipping() instead
     */
    setClipping(min, max){
        this.camera.setClipping(min, max);
    }

    /**
     * @deprecated use camera.setRotationLimit() instead
     */
    setCamRotationLimit(radians)
    {
        this.camera.setRotationLimit(radians);
    }

    /**
     * @deprecated use camera.setAnimationSpeed() instead
     */
    setAnimationSpeed(value)
    {
        this.camera.setAnimationSpeed(value);
    }

    // --------------------------------------------------------------------------------
    //  Mesh
    // --------------------------------------------------------------------------------
    setNewMaterial(meshName)
    {
        let material = new StandardMaterial("myMaterial", this.scene);
        material.diffuseColor = new Color3(1, 0, 1);

        for (let i = 0; i < this.scene.meshes.length; i++)
        {
            if (this.scene.meshes[i].name === meshName)
            {
                this.scene.meshes[i].material = material;
            }
        }
    }

    findMesh(meshName)
    {
        for (let i = 0; i < this.scene.meshes.length; i++)
        {
            if (this.scene.meshes[i].name === meshName)
            {
                return this.scene.meshes[i];
            }
        }
    }

    setTexture(meshName)
    {
        let mesh = this.findMesh(meshName);
        let newMaterial = new PBRMetallicRoughnessMaterial(meshName + "_pbr", this.scene);
        newMaterial.baseColor = new Texture("./texture/HotspotSprite.png", this.scene);
        mesh.material = newMaterial;
    }
  
      setParent(childName, parentName)
    {
        let childObject = this.findMesh(childName);
        childObject.parent = this.findMesh(parentName);
    }

    setAlpha(meshName, alphaValue, alphaMode=2)
    {
        let shader = this.scene.getNodeByName(meshName).material;
        shader.alpha = alphaValue;
        shader.alphaMode = alphaMode;
    }

    // --------------------------------------------------------------------------------
    //  Scene
    // --------------------------------------------------------------------------------
    // Setzt Hintergrundfarbe auf den angegebenen Wert (0.00 - 1.00)
    setBackgroundColor(r, g, b) {
        this.scene.clearColor = new Color4(r, g, b);
    }
}

