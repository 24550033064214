import {onBeforeUnmount, onMounted} from 'vue'

export default function useDetectCanvasClick(component, callback) {
    if (!component)
    {
        return;
    }

    const listener = (event) => {
        if (event.target.tagName !== 'CANVAS') {
            return;
        }

        if (typeof callback === 'function') {
            callback();
        }
    }

    onMounted(() => {
        window.addEventListener('click', listener);
    });

    onBeforeUnmount(() => {
        window.removeEventListener('click', listener);
    });

    return {listener};
}
