import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { Quaternion } from "@babylonjs/core/Maths/math.vector";
import { Color3 } from "@babylonjs/core/Maths/math.color";
import '@babylonjs/core/Rendering/outlineRenderer';
import mergeConfig from "../tools/mergeConfig";

export default class BaseObject
{
    constructor(scene, name, options)
    {
        this.scene = scene;
        this.name = name;
        this.options = options;
        this.defaultState = Object.assign({}, options);
        this.currentState = {};

        this.node = false;
        this.visible = options.visible;
    }

    setState(newState)
    {
        newState = this.initNewState(newState);
        newState = this.mergeNewState(newState);
        this.runStateFunctions(newState);
        return this.currentState;
    }

    initNewState(newState)
    {

        if (typeof newState === 'undefined')
        {
            newState = {};
        }

        if (typeof newState === 'boolean')
        {
            newState = {
                visible: newState
            }
        }

        return newState;
    }

    mergeNewState(newState)
    {
        return mergeConfig(this.defaultState, newState);
    }

    runStateFunctions(newState)
    {
        // visible: Objekt anzeigen / verstecken
        if (this.isChanged(newState, 'visible'))
        {
            this.setVisible(newState.visible);
        }

        // Größe / Scale
        if (this.isChanged(newState, 'scale') && this.isSet(newState, 'scale'))
        {
            this.setScale(newState.scale);
        }

        if (this.isChanged(newState, 'scaling') && this.isSet(newState, 'scaling'))
        {
            this.setScaling(new Vector3(...newState.scaling));
        }

        // Position
        if (this.isChanged(newState, 'position') && this.isSet(newState, 'position'))
        {
            this.setPosition(new Vector3(...newState.position));
        }

        // Rotation
        if (this.isChanged(newState, 'rotation') && this.isSet(newState, 'rotation'))
        {
            this.setRotation(new Vector3(...newState.rotation));
        }

        // setVisibility
        if (this.isChanged(newState, 'visibility'))
        {
            this.setVisibility(newState.visibility);
        }

        // Diffuse
        if (this.isChanged(newState, 'diffuse') && this.isSet(newState, 'diffuse'))
        {
            this.setDiffuse(...newState.diffuse);
        }

        // rotationQuaternion, siehe https://doc.babylonjs.com/features/position,_rotation,_scaling#rotationquaternion
        if (this.isChanged(newState, 'rotationQuaternion') && this.isSet(newState, 'rotationQuaternion'))
        {
            this.setRotationQuaternion(new Quaternion(...newState.rotationQuaternion));
        }

        // BillboardMode
        if (this.isChanged(newState, 'billboardMode'))
        {
            this.setBillboardMode(newState.billboardMode);
        }

        // overlayColor
        if (this.isChanged(newState, 'overlayColor'))
        {
            this.setOverlayColor(newState.overlayColor);
        }

        // overlayAlpha
        if (this.isChanged(newState, 'overlayAlpha'))
        {
            this.setOverlayAlpha(newState.overlayAlpha);
        }

        // Render ID
        if (this.isChanged(newState, 'renderingGroupId'))
        {
            this.setRenderingGroupId(newState.renderingGroupId);
        }

        this.currentState = newState;
    }

    isChanged(newState, field)
    {
        return this.currentState[field] !== newState[field];
    }

    isSet(newState, field)
    {
        if (typeof newState[field] === 'undefined')
        {
            if (process.env.NODE_ENV === 'development')
            {
                // eslint-disable-next-line no-console
                console.error('BabylonStory: Wert für', this.name, field ,'fehlt in config.js!');
            }
            return false;
        }

        return true;
    }

    getValue(value, defaultValue)
    {
        return (typeof value === 'undefined' ? defaultValue : value);
    }

    show()
    {
        this.visible = true;

        if (this.node)
        {
            this.node.setEnabled(true);
        }
    }

    hide()
    {
        this.visible = false;

        if (this.node)
        {
            this.node.setEnabled(false);
        }
    }

    /**
     * Objekt anzeigen oder ausblenden.
     *
     * @param {boolean} value
     */
    setVisible(value)
    {
        if (this.getValue(value, true))
        {
            this.show();
        }
        else
        {
            this.hide();
        }
    }

    /**
     * Objekt Größe ändern (Alle Achsen auf einmal)
     *
     * @param {number} f
     */
    setScale(f)
    {
        if (this.node)
        {
            this.node.scaling = new Vector3(f, f, f);
        }
    }

    /**
     * Objekt Größe ändern (Alle Achsen einzeln)
     *
     * @param {Vector3} scale
     */
    setScaling(scale)
    {
        if (this.node)
        {
            this.node.scaling = scale;
        }
    }

    /**
     * Objekt Position ändern.
     *
     * @param {Vector3} position
     */
    setPosition(position)
    {
        if (this.node)
        {
            this.node.position = position;
        }
    }

    /**
     * Objekt Rotation ändern.
     *
     * @param {Vector3} rotation
     */
    setRotation(rotation)
    {
        if (this.node)
        {
            this.node.rotation = rotation;
        }
    }

    /**
     * Objekt Rotation Quaternion ändern.
     *
     * @param {Vector4} rotationQuaternion
     */
    setRotationQuaternion(rotationQuaternion)
    {
        if (this.node)
        {
            this.node.rotationQuaternion = rotationQuaternion;
        }
    }

    // Transparenz (0 bis 1)
    setVisibility(value)
    {
        if (this.node)
        {
            this.node.visibility = this.getValue(value, 1.0);
        }
    }

    setDiffuse(r, g, b)
    {
        if (this.node && this.node.material)
        {
            let material = this.node.material;
            material.albedoColor = new Color3(r, g, b);
            this.node.material = material;
        }
    }

    /**
     * Zur Kamera ausrichten
     * 1: X-Achse, 2: Y-Achse, 4: Z-Achse, 7: Alle Achsen
     * 3: X & Y-Achse, 5: Z & X-Achse, 6: Z & Y-Achse
     * https://doc.babylonjs.com/typedoc/classes/babylon.abstractmesh#billboardmode
     * @param value integer
     */
    setBillboardMode(value)
    {
        if (this.node)
        {
            this.node.billboardMode = this.getValue(value, false);
        }
    }

    /**
     * Overlay Color zum hervorheben von Objekten
     *
     * @param color Array[3]|Color3|false
     */
    setOverlayColor(color)
    {
        if (this.node)
        {
            // Array [0, 255, 0]
            if (Array.isArray(color))
            {
                this.node.renderOverlay = true;
                this.node.overlayColor = new Color3(...color);
            }
            // Color3
            else if (typeof color === 'object')
            {
                this.node.renderOverlay = true;
                this.node.overlayColor = color;
            }
            // nicht gesetzt
            else
            {
                this.node.renderOverlay = false;
            }
        }
    }

    setOverlayAlpha(alpha)
    {
        if (this.node)
        {
            this.node.overlayAlpha = this.getValue(alpha, 0.5);
        }
    }

    /**
     * Rendering Group ID legt Objekte eine ebene nach vorne (Auch beim Hotspot)
     * @param value Integer: 0, 1, 2, 3
     */
    setRenderingGroupId(value)
    {
        if (this.node)
        {
            this.node.renderingGroupId = this.getValue(value, 0);
        }
    }
}