<template>
    <div v-bind:class="{ 'd-none' : hidden }" class="fullscreen-container fullscreen-comparison">

        <!-- App -->
        <div class="bottom-bar d-flex justify-content-end">
            <button class="btn btn-link" v-on:click="close()">Vollbild schließen X</button>
        </div>
        <!-- else ..?  -->
        <!--<button class="btn btn&#45;&#45;close" v-on:click="close()">x</button>-->

        <ImgComparisonSlider ref="slider" value="50" class="zdf-comparison-slider">
            <!-- eslint-disable -->
            <img ref="before" src="" slot="first" style="width: 100%" alt="" />
            <img ref="after" src="" slot="second" style="width: 100%" alt="" />
            <!-- eslint-enable -->
        </ImgComparisonSlider>

    </div>
</template>

<script>
    export default {
        name: "FullscreenComparison",
        data: function ()
        {
            return {
                hidden: true,
            }
        },
        created()
        {
            this.$eventBus.$on('FullscreenComparison.show', (src1, src2, value) =>
            {
                this.$refs.slider.value = value;
                this.$refs.before.src = src1;
                this.$refs.after.src = src2;

                this.hidden = false;

                this.$tracking.actionClick('Fullscreen-Infobox-Comparison auf');
            });
        },
        methods: {
            close()
            {
                this.$refs.slider.value = 0;
                this.$refs.before.src = "";
                this.$refs.after.src = "";

                this.hidden = true;

                this.$tracking.actionClick('Fullscreen-Infobox-Comparison zu');
            }
        }
    };
</script>


<style scoped lang="scss">

    .fullscreen-comparison {

        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $pinch-zoom-bg !important;
    }
    .zdf-comparison-slider {

        width: calc(100vh * 16 / 9);
        --default-handle-width: 80px;
    }

    .btn-link {

        @include fontFamily(Light);

        color: $warm-gray;
        border-bottom: 1px solid $orange;

        transition: color $transition-slow, border $transition-slow;

        &:hover,
        &:active {
            color: $orange;
            border-bottom-style: dotted;
        }
    }

    .bottom-bar {

        position: absolute;
        z-index: 1;

        bottom: 0px;
        right: 0;
        left: 0px;

        margin: 1rem;
        padding: 1rem;
        background: $gray;
    }
</style>
