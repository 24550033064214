import { ActionManager } from "@babylonjs/core/Actions/actionManager";
import { ExecuteCodeAction } from "@babylonjs/core/Actions/directActions";
import { TransformNode } from "@babylonjs/core/Meshes/transformNode";
import { SceneLoader } from "@babylonjs/core/Loading/sceneLoader";
import '@babylonjs/loaders/glTF';
import MeshObject from "./MeshObject";
import hasOwnProperty from "../../tools/hasOwnProperty";
import EventBus from "@/BabylonStory/tools/event-bus";

export default class BabylonObject extends MeshObject
{
    constructor(scene, filename, name, options)
    {
        super(scene, name, options);


        this.animation = false;
        this.loaded = false;
        this.callback = false;
        this.trigger = options.trigger;

        let self = this;

        let path = process.env.VUE_APP_FOLDER + (process.env.VUE_APP_FOLDER.slice(-1) === '/' ? '' : '/') + "3d-models/";

        // Dateigröße abrufen
        new Promise(resolve => {
            let xhr = new XMLHttpRequest();
            xhr.open('HEAD', path + filename, true);
            xhr.onreadystatechange = () => {
                resolve(+xhr.getResponseHeader("Content-Length"));
                xhr.abort();
            };
            xhr.send();
        }).then((filesize) => {
            EventBus.$emit('LoadingProgress', {
                filename: filename,
                finished: false,
                event: {
                    loaded: 0,
                    total: filesize
                }
            });
        });

        // Objekt laden
        this.promise = SceneLoader.ImportMeshAsync("", path, filename, this.scene,
            function (loaderProgressEvent)
            {
                EventBus.$emit('LoadingProgress', {
                    filename: filename,
                    finished: false,
                    event: loaderProgressEvent
                });
            }
        ).then(function (result) // eslint-disable-line no-unused-vars
        {
            EventBus.$emit('LoadingProgress', {
                filename: filename,
                finished: true,
            });

            let newMeshes = result.meshes;
            let particleSystems = result.particleSystems;
            let skeletons = result.skeletons;
            let animationsGroup = result.animationGroups;

            // Wir erzeugen einen neuen Knoten für den Import
            let obj = new TransformNode(name);
            // Das alte Root wird gesucht und entfernt, weil es keine TransformNode ist
            // können wir damit keine Transformationen machen.
            let rootNode = false;

            // TransformNodes
            for (let i = 0; i < result.transformNodes.length; i++)
            {
                // Dieser Knoten hängt am alten __root__
                if (!rootNode && result.transformNodes[i].parent && result.transformNodes[i].parent.name === '__root__')
                {
                    // Wir merken uns den Knoten, hängen das Objekt aber um
                    rootNode = result.transformNodes[i].parent;
                    result.transformNodes[i].parent = obj;
                }
            }

            // Meshs
            for (let i = 0; i < newMeshes.length; i++)
            {
                // alten __root__ merken
                if (newMeshes[i].name === '__root__')
                {
                    rootNode = newMeshes[i];
                }
                // Sub-Knoten von __root__ an unsere TransformNode hängen
                else if (newMeshes[i].parent && newMeshes[i].parent.name === '__root__')
                {
                    newMeshes[i].parent = obj;
                }

                // Action Trigger erzeugen
                if (self.trigger)
                {
                    newMeshes[i].actionManager = new ActionManager(self.scene);
                    newMeshes[i].actionManager.registerAction(
                        new ExecuteCodeAction(ActionManager.OnPickTrigger, function ()
                        {
                            self.trigger();
                        })
                    );
                }
            }
            // Den normalen __root__ Knoten brauchen wir nicht. Wird gelöscht.
            rootNode.dispose();

            if (animationsGroup && animationsGroup.length)
            {
                self.animation = animationsGroup[0];
                if (self.animation.isStarted)
                {
                    self.animation.stop();
                }
            }

            self.node = obj;

            self.initChilds();

            self.setState(self.options);

            if (self.options.callback)
            {
                self.options.callback(self, newMeshes, particleSystems, skeletons, animationsGroup);
            }

            if (self.callback)
            {
                self.callback(self, newMeshes, particleSystems, skeletons, animationsGroup);
            }

            self.loaded = true;
        });
    }

    runStateFunctions(newState)
    {
        // Animation
        if (hasOwnProperty(newState, 'animation') && this.currentState.animation !== newState.animation)
        {
            if (typeof this.animation.start === 'function')
            {
                if (newState.animation)
                {
                    this.animation.start(false);
                    this.animation.speedRatio = 0.25;
                }
                else
                {
                    this.animation.start(false);
                    this.animation.goToFrame(0);
                    this.animation.stop();
                }
            }
        }

        super.runStateFunctions(newState);
    }

    getPromise()
    {
        return this.promise;
    }
}
