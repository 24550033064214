export default {
    lightIntensity: 1,

    camera: {
        // Position, Ausrichtung und Kamera Bewegung
        // Kamera Position
        position: [.5 * Math.PI, .5 * Math.PI, 20], // alpha, beta, radius
        moveFunction: 'move', // Wie die Kamera bewegt wird: 'move' Kamerafahrt, 'set' Springt zur Position
        target: [0, 0, 0], // Wohin die Kamera blickt
        animationSpeed: 280, // Geschwindigkeit der Kamerabewegung

        // Begrenzungen
        alphaLimits: [null, null],
        betaLimits: [0.01, Math.PI - 0.01],
        radiusLimits: [10, 100], // minCamRadius, maxCamRadius
        clipping: [1, 10000.0],
        fov: 0.8,
        invertRotation: false, // Kamera Steuerung umkehren für 360° Sphere Ansicht
        stopOnClick: true, // Kamera Animation stoppen, bei klick

        // Automatisches Drehen
        useAutoRotationBehavior: false,
        idleRotationSpeed: 0.05,

        // Steuerung
        panSensibility: 0, // 0 is deactivated
        wheelPrecision: 25.0, // 3.0 ist Standard
    },

    // Controller Funktionen, die per babylonState benutzt werden können
    // mit Standardwerten, wenn im State nichts gesetzt ist.
    functionDefaults: {

    }
};
