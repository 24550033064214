<!--

    ---
    OPTION A
    - img, cover ()

    <img src="../assets/img/fallback/model_800x800.jpg" alt="">

    -> cover (default)
    -> contain

    class="o-fit--cover"
    class="o-fit--contain"

    ---
    OPTION B
    - <picture...

    The img element is required as the last child tag of the picture declaration block. The img element is used to provide backward compatibility for browsers that do not support the picture element, or if none of the source tags matched.

    ---
    OPTION C
    - hotspot additional

    ...

-->

<template>
    <div class="fallback-container" v-if="visible">
        <slot>
            <h1>Fallback.vue</h1>
        </slot>
    </div>
</template>


<script>

    export default {
        name: "FallbackContainer",
        data: function ()
        {
            return {
                visible: window.BABYLON_FALLBACK,
            };
        }
    };

</script>


<style scoped lang="scss">

</style>
