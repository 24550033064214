<template>
    <div class="footer">
        <!-- prev -->
        <router-link v-bind:to="prevLink" rel="prev" class="btn btn--prev" v-bind:class="{ 'disabled' : isFirstPage }"
                     v-on-single-click="prev">
            <img class="icon" src="../assets/img/arrow-prev.svg" alt="">
            <span class="help-text help-text--prev">
                <!-- Where support for APNG is missing, only the first frame is displayed -->
                <img src="../assets/img/zurueck_handschrift_anim.png" alt="">
            </span>
        </router-link>
        <!-- dots -->
        <div class="dots">
            <div class="dots-inner">
                <div v-for="(page, index) in pages" :key="index" class="dot" v-bind:style="dotLeftCalculated"
                     v-bind:class="{ 'current-prev': Math.abs(index - currentPage) < 2, 'current': index === currentPage }">
                    {{ index + 1 }}
                </div>
            </div>
            <span class="help-text help-text--timeline">
                <!-- Where support for APNG is missing, only the first frame is displayed -->
                <img src="../assets/img/timeline_handschrift_anim.png" alt="">
            </span>
        </div>
        <!-- next -->
        <router-link v-bind:to="nextLink" rel="next" class="btn btn--next" v-bind:class="{ 'btn--restart': isLastPage}"
                     v-on-single-click="next">
            <img v-if="!isLastPage" class="icon" src="../assets/img/arrow-next.svg" alt="">
            <img v-if="isLastPage" class="icon" src="../assets/img/replay.svg" alt="">
            <span class="help-text help-text--next">
                <!-- Where support for APNG is missing, only the first frame is displayed -->
                <img v-if="!isLastPage" src="../assets/img/weiter_handschrift_anim.png" alt="">
                <img v-if="isLastPage" src="../assets/img/replay.png" alt="">
            </span>
        </router-link>


        <div class="onetrust-consent-cta d-flex align-items-center" v-if="$tracking.environment === 'web' && $config.branding !== 'robotspaceship'">

            <a class="d-desktop-none btn btn-link icon-toggle" v-on:click="toggleFooterFingerprint()">
              <svg width="24" height="24" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor" d="M256.12 245.96c-13.25 0-24 10.74-24 24 1.14 72.25-8.14 141.9-27.7 211.55-2.73 9.72 2.15 30.49 23.12 30.49 10.48 0 20.11-6.92 23.09-17.52 13.53-47.91 31.04-125.41 29.48-224.52.01-13.25-10.73-24-23.99-24zm-.86-81.73C194 164.16 151.25 211.3 152.1 265.32c.75 47.94-3.75 95.91-13.37 142.55-2.69 12.98 5.67 25.69 18.64 28.36 13.05 2.67 25.67-5.66 28.36-18.64 10.34-50.09 15.17-101.58 14.37-153.02-.41-25.95 19.92-52.49 54.45-52.34 31.31.47 57.15 25.34 57.62 55.47.77 48.05-2.81 96.33-10.61 143.55-2.17 13.06 6.69 25.42 19.76 27.58 19.97 3.33 26.81-15.1 27.58-19.77 8.28-50.03 12.06-101.21 11.27-152.11-.88-55.8-47.94-101.88-104.91-102.72zm-110.69-19.78c-10.3-8.34-25.37-6.8-33.76 3.48-25.62 31.5-39.39 71.28-38.75 112 .59 37.58-2.47 75.27-9.11 112.05-2.34 13.05 6.31 25.53 19.36 27.89 20.11 3.5 27.07-14.81 27.89-19.36 7.19-39.84 10.5-80.66 9.86-121.33-.47-29.88 9.2-57.88 28-80.97 8.35-10.28 6.79-25.39-3.49-33.76zm109.47-62.33c-15.41-.41-30.87 1.44-45.78 4.97-12.89 3.06-20.87 15.98-17.83 28.89 3.06 12.89 16 20.83 28.89 17.83 11.05-2.61 22.47-3.77 34-3.69 75.43 1.13 137.73 61.5 138.88 134.58.59 37.88-1.28 76.11-5.58 113.63-1.5 13.17 7.95 25.08 21.11 26.58 16.72 1.95 25.51-11.88 26.58-21.11a929.06 929.06 0 0 0 5.89-119.85c-1.56-98.75-85.07-180.33-186.16-181.83zm252.07 121.45c-2.86-12.92-15.51-21.2-28.61-18.27-12.94 2.86-21.12 15.66-18.26 28.61 4.71 21.41 4.91 37.41 4.7 61.6-.11 13.27 10.55 24.09 23.8 24.2h.2c13.17 0 23.89-10.61 24-23.8.18-22.18.4-44.11-5.83-72.34zm-40.12-90.72C417.29 43.46 337.6 1.29 252.81.02 183.02-.82 118.47 24.91 70.46 72.94 24.09 119.37-.9 181.04.14 246.65l-.12 21.47c-.39 13.25 10.03 24.31 23.28 24.69.23.02.48.02.72.02 12.92 0 23.59-10.3 23.97-23.3l.16-23.64c-.83-52.5 19.16-101.86 56.28-139 38.76-38.8 91.34-59.67 147.68-58.86 69.45 1.03 134.73 35.56 174.62 92.39 7.61 10.86 22.56 13.45 33.42 5.86 10.84-7.62 13.46-22.59 5.84-33.43z" class=""></path>
              </svg>
            </a>

            <div class="collapse align-items-center" v-bind:class="{ 'show': fingerprintVisible}">
              <a v-if="$config.branding === 'heute'" class="small btn btn-link mr-4" href="https://www.zdf.de/dsgvo" target="_blank">Datenschutz</a>
              <a v-if="$config.branding === 'logo'" class="small btn btn-link mr-4" href="https://www.zdf.de/kinder/datenschutz" target="_blank">Datenschutz</a>
              <a class="small btn btn-link" role="button" href="#" v-on:click="openConsent($config.branding)">Datenschutzeinstellungen</a>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "ZdfFooter",
        inject: ['$config'],
        data: function ()
        {
            return {
                currentPage: 0,
                // dotWidth: parseInt(window.getComputedStyle(document.querySelector('.dot')).width),
                dotWidth: (3 * 0.31146),
                fingerprintVisible: false
            }
        },
        computed: {
            pages: function ()
            {
                return this.$router.options.routes;
            },
            isFirstPage: function ()
            {
                return this.currentPage === 0;
            },
            isLastPage: function ()
            {
                return this.currentPage === (this.$router.options.routes.length - 1);
            },
            prevLink: function ()
            {
                if (this.isFirstPage)
                {
                    return '';
                }
                return this.$router.options.routes[this.currentPage - 1];
            },
            nextLink: function ()
            {
                if (this.isLastPage)
                {
                    // Von vorne starten
                    return this.$router.options.routes[0];
                }
                return this.$router.options.routes[this.currentPage + 1];
            },
            dotLeftCalculated: function ()
            {
                let a = this.currentPage + 1;
                a = (a < 4) ? 4 : a;
                a = (a > this.pages.length - 3) ? (this.pages.length - 3) : a;
                let b = 1 + this.pages.length - a;
                let c = (b - a) / 2;
                let d = c * this.dotWidth;
                let e = d + 'rem';
                return {
                    left: e,
                };
            }
        },
        created()
        {
            this.$router.afterEach((to) =>
            {
                for (let i = 0; i < this.$router.options.routes.length; i++)
                {
                    if (this.$router.options.routes[i].path === to.path)
                    {
                        this.currentPage = i;
                    }
                }

                // Page Tracking
                this.$tracking.page(this.$router.options.routes[this.currentPage]);

                // currentPage
                this.$eventBus.$emit('currentPage', this.$data.currentPage);
            })
        },
        mounted()
        {
            // Router mode=abstract => Startseite laden
            if (this.$router.mode === 'abstract')
            {
                this.$router.push(this.$router.options.routes[this.currentPage].path);
            }
            else
            {
                // Aktuelle Seite aus Route übernehmen (this.$route.path geht für dynamische geladene Seiten nicht)
                if (window.location.hash !== '#/')
                {
                    for (let i = 0; i < this.$router.options.routes.length; i++)
                    {
                        if (this.$router.options.routes[i].path === this.$route.path)
                        {
                            this.currentPage = i;

                            // currentPage
                            this.$eventBus.$emit('currentPage', this.$data.currentPage);
                        }
                    }
                }
            }
        },
        methods: {
            next: function ()
            {
                this.$tracking.navigationClick('Seite vor');
            },
            prev: function ()
            {
                this.$tracking.navigationClick('Seite zurück');
            },
            openConsent: function (branding)
            {
                window.zdfcmp.openCMPSettings();

                if (branding === 'logo')
                {
                    const t = document.body.querySelector('#zdf-cmp-customize .zdf-cmp-description');
                    if (t)
                    {
                        t.innerHTML = "".concat("Wenn du noch nicht 18 Jahre alt bist, zeige diesen Text bitte deinen Eltern und lasse sie hierüber entscheiden.<br/><br/>")
                            .concat(t.innerHTML)
                    }
                }
            },
            toggleFooterFingerprint: function()
            {
              this.fingerprintVisible = !this.fingerprintVisible;
            }
        }
    };
</script>

<style lang="scss">

</style>
