<template>
    <div class="fullscreen-image" v-on:click="open()">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "FullscreenImage",
        props: [
            'src',
        ],
        methods: {
            open()
            {
                this.$eventBus.$emit('Fullscreen.show', (this.src));
            },
        }
    }
</script>
