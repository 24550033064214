import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import { Animation } from "@babylonjs/core/Animations/animation";
import { CubicEase, EasingFunction } from "@babylonjs/core/Animations/easing";
import { PointerEventTypes } from "@babylonjs/core/Events/pointerEvents";
import BaseObject from "../BaseObject";
import Tracking from "../../tools/Tracking"

export default class Camera extends BaseObject
{
    constructor(scene, canvas, name, options)
    {
        super(scene, name, options);

        let camera = new ArcRotateCamera(name, options.position[0], options.position[1], options.position[2],
            new Vector3(options.target[0], options.target[1], options.target[2]), scene);

        // Kamera Steuerung
        camera.attachControl(canvas, true, false, false);
        camera.panningSensibility = options.panSensibility; // Kamera kann nicht verschoben werden
        camera.wheelPrecision = options.wheelPrecision;

        // Zoom Limits
        camera.lowerRadiusLimit = options.minCamRadius;
        camera.upperRadiusLimit = options.maxCamRadius;
        camera.upperBetaLimit = Math.PI / 2;

        // CANCEL CAMERA MOVE ANIMATION
        this.cameraMoveAnimation = false;
        scene.onPointerDown = () =>
        {
            if (this.currentState.stopOnClick)
            {
                if (this.cameraMoveAnimation)
                {
                    this.cameraMoveAnimation.stop();
                }
                this.cameraMoveAnimation = false;
            }
        };

        let pointerDrag = false;
        scene.onPointerObservable.add((pointerInfo) => {
            switch (pointerInfo.type) {
                case PointerEventTypes.POINTERDOWN:
                //     console.log("POINTER DOWN", pointerInfo);
                    pointerDrag = false;
                    break;
                case PointerEventTypes.POINTERUP:
                    // console.log("POINTER UP", camera.position,
                    if (pointerDrag)
                    {
                        const tracking = new Tracking();
                        tracking.actionClick('Fingermove');
                    }
                    break;
                case PointerEventTypes.POINTERMOVE:
                    // console.log("POINTER MOVE");
                    pointerDrag = true;
                    break;
                // case PointerEventTypes.POINTERWHEEL:
                //     console.log("POINTER WHEEL", camera.position);
                //     break;
            }
        });

        this.node = camera;
        this.camera = camera;
    }

    runStateFunctions(newState)
    {
        // FOV Field of View (radians)
        if (this.currentState.invertRotation !== newState.invertRotation)
        {
            this.setInvertRotation(newState.invertRotation);
        }
        // FOV Field of View (radians)
        if (this.currentState.fov !== newState.fov)
        {
            this.setFOV(newState.fov);
        }

        // Limits
        // setRadiusLimits
        if (this.currentState.radiusLimits !== newState.radiusLimits)
        {
            this.setRadiusLimits(...newState.radiusLimits);
        }
        // setAlphaLimits
        if (this.currentState.alphaLimits !== newState.alphaLimits)
        {
            this.setAlphaLimits(...newState.alphaLimits);
        }
        // setBetaLimits
        if (this.currentState.betaLimits !== newState.betaLimits)
        {
            this.setBetaLimits(...newState.betaLimits);
        }

        // setAutoRotateBehaviour
        if (this.currentState.useAutoRotationBehavior !== newState.useAutoRotationBehavior)
        {
            this.setAutoRotateBehaviour(newState.useAutoRotationBehavior);
        }
        // setIdleRotationSpeed
        if (this.currentState.idleRotationSpeed !== newState.idleRotationSpeed)
        {
            this.setIdleRotationSpeed(newState.idleRotationSpeed);
        }

        // setClipping
        if (this.currentState.clipping !== newState.clipping)
        {
            this.setClipping(...newState.clipping);
        }

        // setAnimationSpeed
        if (this.currentState.animationSpeed !== newState.animationSpeed)
        {
            this.setAnimationSpeed(newState.animationSpeed);
        }

        // Kamera Bewegung
        if (newState.moveFunction === 'set')
        {
            // Position
            if (this.currentState.position !== newState.position)
            {
                this.setPosition(...newState.position);
            }

            // Target
            if (this.currentState.target !== newState.target)
            {
                this.setTarget(...newState.target);
            }
        }
        else
        if (newState.moveFunction === 'move')
        {
            this.move(...newState.position.concat(newState.target))
        }
        // move
        // setCamera
        // zoomCamera

        this.currentState = newState;
    }

    move(alpha, beta, radius, newX, newY, newZ)
    {
        let positionIsIdentical = false;

        if (typeof newX === 'undefined')
        {
            newX = this.currentState.target[0];
            newY = this.currentState.target[1];
            newZ = this.currentState.target[2];
        }

        let targetVector = new Vector3(newX, newY, newZ);

        // ------------------------------------------------
        let rotateFunc = (alphaPresent) =>
        {
            let toAlpha;
            //alpha
            let alphaLast = this.camera.alpha; //camera.alpha;
            let alphaDelta = alphaLast % (2 * Math.PI);//[0,2Pi]
            let alphaLarge = alphaLast - alphaDelta;
            // var angle=alphaDelta-alphaPresent;
            let absAlphaAngle = Math.abs(alphaDelta - alphaPresent);
            if (absAlphaAngle > Math.PI)
            {// 0 to 2Pi or 2Pi to 0
                if (alphaDelta >= alphaPresent)
                {//clockwise
                    toAlpha = alphaPresent + alphaLarge + 2 * Math.PI;
                }
                else
                {
                    toAlpha = alphaPresent + alphaLarge - 2 * Math.PI;
                }
            }
            else
            {
                toAlpha = alphaPresent + alphaLarge;
            }
            return toAlpha;
        };
        // ------------------------------------------------
        if (this.camera.alpha === rotateFunc(alpha)
            && this.camera.beta === beta
            && this.camera.radius === radius
            && this.camera.target === targetVector)
        {
            positionIsIdentical = true;
        }

        if (!positionIsIdentical)
        {
            this.camera.detachControl(this.canvas);


            let targetAnim = new Animation("targetAnim", "target", 30,
                Animation.ANIMATIONTYPE_VECTOR3, Animation.ANIMATIONLOOPMODE_CYCLE); //ANIMATIONLOOPMODE_CONSTANT);
            let targetKeys = [{frame: 0, value: this.camera.target}, {
                frame: this.animationLength,
                value: targetVector
            }];
            targetAnim.setKeys(targetKeys);

            let easingFunction = new CubicEase();
            easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
            targetAnim.setEasingFunction(easingFunction);
            this.camera.animations.push(targetAnim);


            // Doku https://doc.babylonjs.com/babylon101/animations
            let alphaAnim = new Animation("alphaAnim", "alpha", 30,
                Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE); //ANIMATIONLOOPMODE_CONSTANT);
            let betaAnim = new Animation("betaAnim", "beta", 30,
                Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE); //ANIMATIONLOOPMODE_CONSTANT);
            let radiusAnim = new Animation("radiusAnim", "radius", 30,
                Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE); //ANIMATIONLOOPMODE_CONSTANT);

            let alphaKeys = [{frame: 0, value: this.camera.alpha}, {
                frame: this.animationLength,
                value: rotateFunc(alpha)
            }];
            let betaKeys = [{frame: 0, value: this.camera.beta}, {frame: this.animationLength, value: beta}];
            let radiusKeys = [{frame: 0, value: this.camera.radius}, {
                frame: this.animationLength,
                value: radius
            }];

            alphaAnim.setKeys(alphaKeys);
            betaAnim.setKeys(betaKeys);
            radiusAnim.setKeys(radiusKeys);

            // Beschleunigen und Abbremsen
            //let easingFunction = new CubicEase();
            easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
            alphaAnim.setEasingFunction(easingFunction);
            betaAnim.setEasingFunction(easingFunction);
            radiusAnim.setEasingFunction(easingFunction);

            this.camera.animations.push(alphaAnim);
            this.camera.animations.push(betaAnim);
            this.camera.animations.push(radiusAnim);

            this.cameraMoveAnimation = this.scene.beginAnimation(this.camera, 0, this.animationLength, false, 3, () =>
            {

                this.camera.attachControl(true, false, false);

            });
        }
    }

    setPosition(alpha, beta, radius)
    {
        this.camera.alpha = alpha;
        this.camera.beta = beta;
        this.camera.radius = radius;
    }

    setFOV(radians)
    {
        this.camera.fov = radians;
    }

    setInvertRotation(value)
    {
        this.camera.invertRotation = value;
    }

    setTarget(newX, newY, newZ)
    {
        this.camera.target = new Vector3(newX, newY, newZ);
    }

    setRadiusLimits(min, max)
    {
        this.camera.lowerRadiusLimit = min;
        this.camera.upperRadiusLimit = max;
    }

    setAlphaLimits(min, max)
    {
        this.camera.lowerAlphaLimit = min;
        this.camera.upperAlphaLimit = max;
    }
    setBetaLimits(min, max)
    {
        this.camera.lowerBetaLimit = min;
        this.camera.upperBetaLimit = max;
    }

    zoom(radians)
    {
        let scene = this.scene;
        let self = this;
        let zoomAnim = new Animation("zoomAnim", "fov", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);

        let zoomKeys = [{frame: 0, value: scene.activeCamera.fov}, {frame: this.animationLength, value: radians}];
        zoomAnim.setKeys(zoomKeys);

        let easingFunction = new CubicEase();
        easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
        zoomAnim.setEasingFunction(easingFunction);
        scene.activeCamera.animations.push(zoomAnim);
        this.scene.beginAnimation(scene.activeCamera, 0, this.animationLength, false, 3, function ()
        {
            self.camera.attachControl(true, false, false);
        });
    }

    setAutoRotateBehaviour(bool){
        this.camera.useAutoRotationBehavior = bool;
    }

    // Bestimmt die Geschwindigkeit und Richtung (- im, + gegen den Uhrzeigensinn)
    setIdleRotationSpeed(number)
    {
        if (this.camera.autoRotationBehavior)
        {
            this.camera.autoRotationBehavior.idleRotationSpeed = number;
        }
    }

    setClipping(min, max){
        this.camera.minZ = min;
        this.camera.maxZ = max;
    }

    setRotationLimit(radians) // "Math.PI / 2" kann nicht unter das Modell schauen, "Math.PI" kann unter das Modell schauen
    {
        this.camera.upperBetaLimit = radians;
    }

    setAnimationSpeed(value)
    {
        this.animationLength = value;
    }
}
