import EventBus from "./event-bus";
import hasOwnProperty from "./hasOwnProperty";

const backgroundTrack = 'background';

export default class AudioController
{
    constructor(config)
    {
        if (AudioController._instance)
        {
            return AudioController._instance
        }
        AudioController._instance = this;

        this.config = config;
        this.audioTracks = {};

        if (!this.isEnabled())
        {
            return;
        }

        // Stop Audio on page change
        EventBus.$on('currentPage', () =>
        {
            this.forAllTracks((track) =>
            {
                if (track !== 'background')
                {
                    this.stop(track);
                }
            });
        });

        // mute/unmute: wir machen hier pause statt mute
        this.muted = true;
        EventBus.$on('audio.toggle', () =>
        {
            this.muted = !this.muted;
            this.forAllTracks((track) =>
            {
                if (this.muted)
                {
                    this.pause(track);
                }
                else
                {
                    this.play(track);
                }
            });
        });

        // ZDF Video Player
        // Video an => Audio aus
        EventBus.$on('video.playing', () =>
        {
            //console.log('AudioController video.playing');
            this.forAllTracks((track) =>
            {
                this.pause(track);
            });
        });

        // Video aus => Audio an
        EventBus.$on('video.stopped', () =>
        {
            this.forAllTracks((track) =>
            {
                if (!this.muted)
                {
                    this.play(track);
                }
            });
        });

        if (config.background)
        {
            EventBus.$on('currentPage', () =>
            {
                if (!this.getTrack(backgroundTrack))
                {
                    this.initTrack(backgroundTrack, config.background, true, false);
                }
            });
        }
    }

    static instance()
    {
        if (!AudioController._instance)
        {
            throw new Error('AudioController singleton not created.');
        }

        return AudioController._instance;
    }

    isEnabled()
    {
        return this.config.enabled;
    }

    forAllTracks(callback)
    {
        for (let track in this.audioTracks)
        {
            if (hasOwnProperty(this.audioTracks, track))
            {
                callback.bind(this)(track);
            }
        }
    }

    initTrack(trackName, src, loop, autoplay)
    {
        if (!this.isEnabled())
        {
            return;
        }

        // Wenn der Ton angemacht wurde, bleibt er auf der nächsten Seite an.
        if (!this.muted)
        {
            autoplay = true;
        }

        let track = this.getTrack(trackName);
        if (!track)
        {
            // Audio Player erzeugen
            track = document.createElement("audio");
            track.id = "AudioController_" + trackName;
            track.src = src;
            track.autoplay = autoplay;
            track.loop = loop;
            track.style.display = 'none';
            document.body.appendChild(track);

            this.audioTracks[trackName] = track;
        }
        else
        {
            // Zurückspulen
            track.currentTime = 0;
            // Audio Datei setzen
            track.src = src;
            track.loop = loop;
        }

        // Abspielen
        if (autoplay)
        {
            track.play();
        }

        // Audio Button anzeigen
        EventBus.$emit('showAudio');
    }

    getTrack(trackName)
    {
        return this.audioTracks[trackName];
    }

    play(trackName)
    {
        let track = this.getTrack(trackName);
        if (track)
        {
            track.play();
        }
    }

    pause(trackName)
    {
        let track = this.getTrack(trackName);
        if (track)
        {
            track.pause();
        }
    }

    stop(trackName)
    {
        let track = this.getTrack(trackName);
        if (track)
        {
            track.pause();
            track.src = '';
        }
    }

    stopAllTracks()
    {
        this.forAllTracks((track) =>
        {
            this.stop(track);
        });
    }

    setMute(trackName, muted)
    {
        let track = this.getTrack(trackName);
        if (track)
        {
            track.muted = muted;
        }
    }
}