/* global ATInternet, iom */

import URL from "./URL";
import {ScrollTracking} from "./ScrollTracking";

export default class Tracking
{
    constructor(config, router)
    {
        if (Tracking._instance)
        {
            return Tracking._instance
        }
        Tracking._instance = this;

        this.atinternet = false;
        this.iam = false;
        this.router = router;
        this.config = Object.assign(config);
        /* Beispiel
        let config = {
            storyName: 'Name der 3D Story',
            storyID: '3D Story-ID',
        };
         */

        // Umgebung der Anwendung erkennen (web, HeuteApp iOS, HeuteApp Android)
        this.environment = 'web';
        // HeuteApp erkennen
        if (window.navigator.userAgent.indexOf('HeuteApp/') !== -1)
        {
            // iOS: Mozilla/5.0 (iPhone; CPU iPhone OS 13_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 HeuteApp/3.0 (de.zdf.heute; build:126; iOS 13.3.1)
            // Android: Mozilla/5.0 (Linux; Android 8.1.0; Nexus 6P Build/OPM7.181205.001; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/80.0.3987.149 Mobile Safari/537.36 HeuteApp/3.0
            if ((window.navigator.userAgent.indexOf('iPhone') !== -1)
                || (window.navigator.userAgent.indexOf('iPad') !== -1)
                || (window.navigator.userAgent.indexOf('iPod') !== -1)
            )
            {
                this.environment = 'ios';
            }
            else
            {
                this.environment = 'android';
            }
        }

        const envValues = {
            web: {
                st: 'zdf',
                level1: '569006'
            },
            ios: {
                st: 'iOS',
                level1: '577758'
            },
            android: {
                st: 'Android',
                level1: '577765'
            }
        };

        // tracking.environment vom Parameter
        if (URL.hasQuery('env'))
        {
            this.environment = URL.getQuery('env');
        }

        this.env = envValues[this.environment];

        if (typeof this.config.storyName === 'undefined')
        {
            throw 'Name der 3D Story fehlt in der Tracking config (storyName)';
        }
        if (typeof this.config.storyID === 'undefined')
        {
            throw 'ID der 3D Story fehlt in der Tracking config (storyID)';
        }

        // Tracking deaktivieren für Tests
        if (config.disabled)
        {
            this.doNotTrack = true;

            if (process.env.NODE_ENV === 'development')
            {
                // eslint-disable-next-line no-console
                console.log('Babylon Story: Tracking is disabled.');
            }

            return;
        }

        // doNotTrack
        this.doNotTrack = window.navigator.userAgent.indexOf('notracking') > -1;
        if (this.doNotTrack)
        {
            if (process.env.NODE_ENV === 'development')
            {
                // eslint-disable-next-line no-console
                console.debug('Do not track is set - ignoring');
            }
        }

        this.updateConsentPromise();

        // LoadingTime
        // Wird von Footer.created() beendet
        this.loadingTimeStart = performance.now();
    }

    static instance()
    {
        if (!Tracking._instance)
        {
            throw new Error('Tracking singleton not created.');
        }

        return Tracking._instance;
    }

    updateConsentPromise()
    {
        this.consentPromise = new Promise((resolve) =>
        {
            if (this.environment !== 'web')
            {
                return resolve();
            }

            window.zdfcmp.addConsentChangedCallback(() =>
            {
                if (window.zdfcmp.hasTrackingConsent())
                {
                    return resolve();
                }
                else
                {
                    this.updateConsentPromise();
                }
            });
        });
    }

    loadScript(url, scriptid)
    {
        let scriptEl = document.createElement('script');
        scriptEl.type = 'text/javascript';
        scriptEl.async = true;
        scriptEl.src = url;
        scriptEl.id = scriptid;

        document.getElementsByTagName('head')[0].appendChild(scriptEl);

        return new Promise((res) => {
            scriptEl.onload = function() {
                res();
            }
        });
    }

    loadATI()
    {
        if (!this.atinternet)
        {
            this.atinternet = this.loadScript('https://module.zdf.de/atinternet/live/smarttag.js', 'atinternet')
                .then(() =>
                {
                    if (typeof ATInternet != 'undefined')
                    {
                        this.tag = new ATInternet.Tracker.Tag({
                            site: this.env.level1,
                            collectDomain: 'logc407.xiti.com',
                            collectDomainSSL: 'logs1407.xiti.com'
                        });
                    }
                });
        }

        return this.atinternet;
    }

    loadIAM()
    {
        if (!this.iam)
        {
            this.iam = this.loadScript('https://script.ioam.de/iam.js', 'ivw');
        }

        return this.iam;
    }

    getPageName()
    {
        if (this.router.currentRoute.meta && this.router.currentRoute.meta.title)
        {
            return this.router.currentRoute.meta.title;
        }

        return this.router.currentRoute.path;
    }

    page(route)
    {
        if (this.doNotTrack)
        {
            return;
        }

        let pageName = route.path;

        // SZM-Page Tag
        let iam_data = {
            "st": this.env.st,
            "cp": "Nachrichten/Stories",
            "co": "3d-tool" + "/" + pageName + '/' + this.config.storyID, // content type/page titel/Sophora-ID
            "sv": "ke",
            // MCVD method activated
            "sc": "yes", // ?
        };
        if (this.environment === 'android')
        {
            iam_data.st = 'aadheute';
        }
        else if (this.environment === 'ios')
        {
            iam_data.st = 'appheute';
        }

        // ATI-Tagging
        let at_data = {
            "level1": this.env.level1,
            "level2": "15", // für Nachrichten
            "chapter1": "Stories",
            "chapter2": "3D-Tool",
            "chapter3": this.config.storyName + '|' + pageName + '|' + this.config.storyID,
            "name": this.config.storyName,
            "customObject": {}
        };

        // Webseite
        if (this.environment !== 'web')
        {
            at_data["chapter3"] = this.config.storyName + '|' + pageName;
            at_data["customObject"]["Sophora-ID"] = this.config.storyID;
        }

        // Fallback Version tracken
        if (window.BABYLON_FALLBACK)
        {
            at_data["customObject"]["version"] = "Fallback";
        }

        // IAM Tracking
        this.consentPromise.then(() =>
        {
            this.loadIAM().then(() =>
            {
                if (typeof (iom) !== 'undefined')
                {
                    if (process.env.NODE_ENV === 'development')
                    {
                        // eslint-disable-next-line no-console
                        console.debug('track.page IAM', iam_data);
                    }

                    iom.c(iam_data, 1);
                }
            });
        });

        // ATI Tracking
        this.consentPromise.then(() =>
        {
            this.loadATI().then(() =>
            {
                if (typeof this.tag != 'undefined')
                {
                    if (process.env.NODE_ENV === 'development')
                    {
                        // eslint-disable-next-line no-console
                        console.debug('track.page AT', at_data);
                    }

                    this.tag.page.set(at_data);
                    this.tag.dispatch();
                }
            });
        });
    }

    click(label, type, customObject)
    {
        if (this.doNotTrack)
        {
            return;
        }

        // ATI-Tagging
        let at_data = {
            "level1": this.env.level1,
            "level2": "15", // für Nachrichten
            "chapter1": "Stories",
            "chapter2": "3D-Tool",
            // #Snippet-Name | Horizontal position of story element | Snippet-ID#
            // Click:Context:Storyelement:Giftiger_Bleistaub|HPos:5|23-5'
            "chapter3": label,
            "name": this.config.storyName,
            // exit', 'download', 'action' oder 'navigation'
            "type": type,
        };

        if (customObject)
        {
            at_data['customObject'] = customObject;
        }

        this.consentPromise.then(() =>
        {
            this.loadATI().then(() =>
            {
                if (typeof this.tag != 'undefined')
                {
                    if (process.env.NODE_ENV === 'development')
                    {
                        // eslint-disable-next-line no-console
                        console.debug('track.click', at_data);
                    }
                    this.tag.click.set(at_data);
                    this.tag.dispatch();
                }
            });
        });
    }

    navigationClick(label)
    {
        this.click(label, 'navigation');
    }

    actionClick(label)
    {
        this.click(label, 'action');
    }

    hotspotClick(label)
    {
        this.click(this.getPageName() + '|' + label, 'action');
    }

    loadingTime()
    {
        // LoadingTime
        let loadingTime = (performance.now() - this.loadingTimeStart) * 0.001; // msec in sec
        this.click('LoadingTime', 'action', {LoadingTime: loadingTime + 'sec'});
    }

    addScroll(label, scrollElement)
    {
        new ScrollTracking(this, label, scrollElement);
    }

    // scroll tracking
    scroll(label, scrollPercent, percent)
    {
        // kein Tracking geladen
        if (!this.tag || this.doNotTrack)
        {
            return;
        }

        let at_data = {
            impression: {
                campaignId: '[' + encodeURIComponent('Scroll-Tracking') + ']',
                creation: '[' + encodeURIComponent(scrollPercent + '%' + percent) + ']',
                variant: '[' + encodeURIComponent('5-60sec') + ']', // # Dauer-bis-Event #
                // Beispiel: '5-60sec', '0.5-1min'
                format: '[' + encodeURIComponent('3D-Info') + ']',
                generalPlacement: '[' + encodeURIComponent(this.getPageName()) + ']',
                detailedPlacement: '[' + encodeURIComponent(label) + ']',
            },
        };

        this.consentPromise.then(() =>
        {
            this.loadATI().then(() =>
            {
                if (typeof this.tag != 'undefined')
                {
                    if (process.env.NODE_ENV === 'development')
                    {
                        // eslint-disable-next-line no-console
                        console.debug('track.scroll', at_data);
                    }

                    this.tag.publisher.set(at_data);
                    this.tag.dispatch();
                }
            });
        });
    }

    // TOOD Rich Media Tracking
    // wir haben gar keine Playtime, Duration, Ende der Wiedergabe
    richMediaTracking()
    {

    }
}