import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { Vector3 } from "@babylonjs/core/Maths/math";
import { Sprite } from "@babylonjs/core/Sprites/sprite";
import { SpriteManager } from "@babylonjs/core/Sprites/spriteManager";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { ActionManager } from "@babylonjs/core/Actions/actionManager";
import { ExecuteCodeAction } from "@babylonjs/core/Actions/directActions";

import EventBus from "../../tools/event-bus";
import BaseObject from "../BaseObject";
import Tracking from "@/BabylonStory/tools/Tracking";

export default class BabylonHotspot extends BaseObject
{
    constructor(babylonController, name, options)
    {
        super(babylonController.scene, name, options);
        let self = this;

        // Mesh.CreatePlane
        let plane = Mesh.CreatePlane(this.name + '_' + name, this.options.size, this.scene);
        plane.position = new Vector3(...this.options.position);
        //plane.scaling = 3; //this.options.scaling;
        plane.billboardMode = AbstractMesh.BILLBOARDMODE_ALL;

        plane.material = new StandardMaterial("planeMaterial_" + name, this.scene);
        /*
            plane.material.diffuseTexture = new BABYLON.Texture("texture/plain.png", this.scene);
            //plane.material.backFaceCulling = false;
            plane.material.diffuseTexture.hasAlpha = true;
        */
        plane.material.alpha = 0;

        // SpriteManager
        let hotspotSpriteImageUrl = (this.options.imgUrl) ? this.options.imgUrl : 'texture/hotspotSprite-v2.png';
        let hotspotSprite = new SpriteManager("hotspotSprite_" + name, hotspotSpriteImageUrl, 10, 256, this.scene);

        let sprite = new Sprite("HotSpot_" + name, hotspotSprite);
        sprite.playAnimation(0, 45, true, 40);
        sprite.position = new Vector3(...this.options.position);
        sprite.size = this.getValue(this.options.size, 1);

        // Hotspot visited
        const storage_key = 'hostspot_visited_' + name;
        // is visited?
        if( window.sessionStorage.getItem(storage_key) === '1' ) {
            sprite.loopAnimation = false;
        }

        this.node = plane;
        this.sprite = sprite;
        this.hotspotSprite = hotspotSprite;


        // Trigger, Action und InfoBox
        this.node.actionManager = new ActionManager(this.scene);
        this.node.actionManager.registerAction(
            new ExecuteCodeAction(ActionManager.OnPickTrigger, function ()
            {
                // set visited
                window.sessionStorage.setItem(storage_key, '1');
                // stop loop
                self.sprite.loopAnimation = false;

                Tracking.instance().hotspotClick('Hotspot: ' + name);

                if (self.currentState.infoBox)
                {
                    // Events entkoppeln, sonst geht mobil der touch up noch in die InfoBox und öffnet dort ggf. ein Bild.
                    babylonController.timer.add(500, () => {
                        EventBus.$emit('InfoBox.show', self.currentState.infoBox);
                    });
                }

                if (typeof self.currentState.trigger === 'function')
                {
                    self.currentState.trigger(babylonController);
                }
            })
        );

        if (!this.options.visible)
        {
            this.hide();
        }

        this.setState(options);
    }

    dispose()
    {
        this.node.actionManager.dispose();
        this.node.material.dispose();
        this.hotspotSprite.dispose();
        this.sprite.dispose();
        this.node.dispose();
    }

    show()
    {
        super.show();
        this.sprite.isVisible = true;
    }

    hide()
    {
        super.hide();
        this.sprite.isVisible = false;
    }

    setScale(scale)
    {
        super.setScale(scale);
        this.sprite.size = scale;
    }

    initNewState(newState)
    {
        newState = super.initNewState(newState);

        if (typeof newState === 'string')
        {
            newState = {
                visible: true,
                infoBox: newState,
            };
        }

        return newState;
    }

    setPosition(position)
    {
        super.setPosition(position);
        this.sprite.position = position;
    }

    setRenderingGroupId(value)
    {
        if (this.hotspotSprite)
        {
            this.hotspotSprite.renderingGroupId = this.getValue(value, 0);
        }
    }
}
