<template>
    <div class="home">
        <div class="home__background">
            <picture>
                <slot name="picture"></slot>
                <!-- The img element is required as the last child tag of the picture declaration block. The img element is used to provide backward compatibility for browsers that do not support the picture element, or if none of the source tags matched. -->
                <img class="o-fit"
                     v-bind:class="{ 'o-fit--contain' : fit === 'contain' || !fit, 'o-fit--cover' : fit === 'cover'  }"
                     src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" alt="" />
            </picture>
        </div>
        <div class="grid">
            <div class="a story-label text-uppercase">3D-Interaktiv</div>
            <div class="overflow-auto mask-image">
               <div class="block">
                    <div class="story-headline">
                        <slot name="headline"></slot>
                    </div>
                    <div class="keyline-container position-relative my-1 py-1">
                        <div class="keyline"></div>
                    </div>
                    <div class="story-subline">
                        <slot name="subline"></slot>
                    </div>
                    <div class="story-text">
                        <slot name="text"></slot>
                    </div>
                </div>
            </div>
            <div class="block">
                <div class="cta pt-2 d-flex justify-content-center">
                    <LoadingProgress class="visible-babylon-loading"></LoadingProgress>
                    <div class="hidden-babylon-loading">
                        <router-link v-bind:to="$router.options.routes[1]" rel="next" class="btn btn--start" v-on-single-click="() => next()">
                            <img class="icon" src="../assets/img/arrow-next-lg.svg" alt="">
                            <img
                                v-if="$config.branding !== 'robotspaceship'"
                                class="help-text help-text--start"
                                src="../assets/img/start_landscape_handschrift_anim.png" alt="">
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="$config.branding === 'heute'" class="zdfheute-logo">
            <img class="img-fluid" src="../assets/img/zdfheute_logo.png" alt="">
        </div>

        <div v-if="$config.branding === 'robotspaceship'" class="zdfheute-logo">
            <img class="img-fluid" src="../assets/img/robotspaceship-logo.svg" alt="">
        </div>

        <div v-if="$config.branding === 'logo'" class="zdflogo-logo">
            <img class="img-fluid" src="../assets/img/logo-Logo.png" alt="">
        </div>

        <div v-if="$config.branding === 'logo'" class="zdftivi-logo">
            <img class="img-fluid" src="../assets/img/ZDFtivi_Logo_weiss_600px.png" alt="">
        </div>

        <div class="story-update text-white text-right">
            <slot name="update"></slot>
        </div>
    </div>
</template>

<script>
    import LoadingProgress from "@/BabylonStory/components/LoadingProgress";

    export default {
        name: "Home",
        props: ['fit'],
        inject: ['$config'],
        components: {
            LoadingProgress
        },
        methods: {
            next: function () {
                this.$tracking.navigationClick('Start');
                this.$eventBus.$emit('nextPage');
            }
        },
        mounted: function () {
            let box = document.querySelector('.mask-image');
            function watch() {
                let bool = (box.scrollHeight == (box.scrollTop + box.clientHeight));
                box.classList.toggle('scroll-end', bool);
            }
            box.addEventListener('scroll', watch);
        },
    }
</script>


<style scoped lang="scss">

</style>
