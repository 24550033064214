<template>
    <div v-bind:class="{ 'd-none' : hidden }" class="fullscreen-container">


        <!-- App -->
        <div class="bottom-bar d-flex justify-content-end">
            <button class="btn btn-link" v-on:click="close()">Vollbild schließen X</button>
        </div>

        <div ref="PinchZoom" class="pinch-zoom-wrapper">
            <div class="pinch-zoom-content">
                <img ref="image" :src="image" alt="" @load="imageLoaded()">
            </div>
        </div>

    </div>
</template>

<script>
import panzoom from 'panzoom';

    export default {
        name: "FullscreenContainer",
        data: function ()
        {
            return {
                hidden: true,
                pinchZoom: null,
                image: '',
            }
        },
        created()
        {
            this.$eventBus.$on('Fullscreen.show', (src) =>
            {
                this.image = src;
                this.hidden = false;
                this.$tracking.actionClick('Fullscreen-Infobox-Image auf');
            });
        },
        mounted()
        {
            this.pinchZoom = panzoom(this.$refs.PinchZoom, {
                maxZoom: 5,
                minZoom: 0.2,
                onTouch: function() {
                    return false; // tells the library to not preventDefault.
                }
            });
        },
        methods: {
            imageLoaded()
            {
                const imageWidth = this.$refs.image.clientWidth;
                const imageHeight = this.$refs.image.clientHeight;
                const windowWidth = window.innerWidth;
                const windowHeight = window.innerHeight;

                const zoom = Math.min(windowWidth / imageWidth, windowHeight / imageHeight);
                const x = (windowWidth / 2) - (zoom * imageWidth / 2);
                const y = (windowHeight / 2) - (zoom * imageHeight / 2);

                this.pinchZoom.zoomAbs(0, 0, zoom);
                this.pinchZoom.moveTo(x, y);
            },
            close()
            {
                this.image = '';
                this.hidden = true;

                this.$tracking.actionClick('Fullscreen-Infobox-Image zu');
            }
        }
    };
</script>


<style scoped lang="scss">
    .fullscreen-container {
        background-color: $body-bg;
    }

    .btn-link {

        @include fontFamily(Light);

        color: $warm-gray;
        border-bottom: 1px solid $orange;

        transition: color $transition-slow, border $transition-slow;

        &:hover,
        &:active {
            color: $orange;
            border-bottom-style: dotted;
        }
    }

    .bottom-bar {

        position: absolute;
        z-index: 1;

        bottom: 0;
        right: 0;
        left: 0;

        margin: 1rem;
        padding: 1rem;
        background: $gray;
    }
</style>
