// Libs
import 'pepjs'
const modernizr = require("modernizr");
import VueLazyload from "vue-lazyload";
import BabylonController from "./babylon/Controller.js";
import { ImgComparisonSlider } from '@img-comparison-slider/vue';

// Componenten
import ZdfFooter from './components/ZdfFooter';
import Header from './components/ZdfHeader';
import Help from './components/Help';
import InfoBox from './components/InfoBox';
import TextBanner from './components/TextBanner';
import Fallback from './components/Fallback';
import Apng from './components/Apng';
import FullscreenContainer from './components/FullscreenContainer';
import FullscreenComparison from './components/FullscreenComparison';
import FullscreenImage from './components/FullscreenImage';
import FullscreenImageComparison from './components/FullscreenImageComparison';
import ZDFPlayer from "./components/ZDFPlayer";
import PageNotFound from "./components/PageNotFound";
import Home from "./components/Home";
import StateDebugger from "./components/StateDebugger";
import ChildAreaProtection from "./components/ChildAreaProtection";


// Tools
import EventBus from "./tools/event-bus";
import Tracking from "./tools/Tracking";
import AudioController from "./tools/AudioController";
import URL from "./tools/URL";
import preventDoubleClick from "./tools/preventDoubleClick";

// Current Device: Wir nutzen nur zwei CSS Klassen: .iphone und .ipad
import device from 'current-device';
// Wir rufen die Funktionen auf, damit wir kein unused import haben und es keine TreeShaking Probleme gibt.
device.iphone();
device.ipad();

export default {
    install: function (app, data) {
        this.forceHttps();

        app.provide('$eventBus', EventBus);
        app.provide('$tracking', new Tracking(data.config.tracking, data.router));
        app.provide('$config', data.config);

        // Babylon JS
        this.loadBabylon(app, data);

        // Seiten Title
        this.pageTitle(data);

        // 404 Weiterleitung auf Start
        this.redirect404(data);

        // Audio Controller
        app.provide('$audioController', new AudioController(data.config.audio));

        this.preventDoubleClick(app);

        // Komponenten für Seiten
        app.component('ZdfFooter', ZdfFooter);
        app.component('ZdfHeader', Header);
        app.component('Help', Help);
        app.component('InfoBox', InfoBox);
        app.component('TextBanner', TextBanner);
        app.component('Fallback', Fallback);
        app.component('Apng', Apng);
        app.component('FullscreenContainer', FullscreenContainer);
        app.component('FullscreenComparison', FullscreenComparison);
        app.component('FullscreenImage', FullscreenImage);
        app.component('FullscreenImageComparison', FullscreenImageComparison);
        app.component('ZDFPlayer', ZDFPlayer);
        app.component('Home', Home);
        app.component('StateDebugger', StateDebugger);
        app.component('ChildAreaProtection', ChildAreaProtection);
        app.component('VueLazyload', VueLazyload);
        app.component('ImgComparisonSlider', ImgComparisonSlider);
        app.use(VueLazyload, {
            // preLoad: 1.3,
            // error: 'dist/error.png',
            // loading: 'dist/loading.gif',
            // attempt: 1,
            // lazyComponent: true
            adapter: {
                loaded ({ bindType, el, naturalHeight, naturalWidth, $parent, src, loading, error, Init }) { // eslint-disable-line no-unused-vars
                    let loader = el.parentNode.querySelector('.zdf-load-icon');
                    if (loader) {
                        el.parentNode.removeChild(loader);
                    }
                },
                loading (listender, Init) { // eslint-disable-line no-unused-vars
                    let w = listender.el.dataset.width, h = listender.el.dataset.height;
                    listender.el.src = `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${w} ${h}"%3E%3C/svg%3E`;
                },
            }
        });


        app.mixin({
            inject: ['$eventBus', '$tracking', '$babylonController', '$audioController'],
            methods: {
                infobox(box) {
                    // fallback hotspot v-on:click
                    this.$eventBus.$emit('InfoBox.show', box);
                }
            }
        });

    },

    /**
     * Force https for production
     */
    forceHttps()
    {
        if (process.env.VUE_APP_FORCE_HTTPS === '1' && location.protocol !== "https:")
        {
            location.protocol = "https:";
        }
    },

    /**
     * Babylon JS laden oder Fallback Lösung mit statischen Bildern, wenn 3D nicht geht
     * @param app
     * @param data
     */
    loadBabylon(app, data)
    {
        // ohne webGL => Fallback auf statische Bilder ohne 3D
        let fallback = !(modernizr.webgl && modernizr.webglextensions);

        // Fallback per Parameter anschalten zum Testen
        if (URL.hasQuery('fallback'))
        {
            fallback = !!URL.getQuery('fallback');
        }

        window.BABYLON_FALLBACK = fallback;
        document.querySelector('html').classList.add((fallback) ? 'no-babylon' : 'babylon');

        const babylonController = new BabylonController();
        app.provide('$babylonController', babylonController);
        if (!fallback)
        {
            document.onreadystatechange = () =>
            {
                if (document.readyState === "complete" && !fallback)
                {
                    babylonController.init(data.config);
                }
            };
        }

        app.mixin({
            mounted: function ()
            {
                const fct = () =>
                {
                    // Set Babylone State from babylonState option
                    if (this.$options.babylonState)
                    {
                        this.$babylonController.setState(this.$options.babylonState);
                    }
                    // call babylonReady
                    if (this.$options.babylonReady)
                    {
                        this.$options.babylonReady.bind(this)();
                    }
                };

                if (this.$babylonController && this.$babylonController.ready)
                {
                    fct();
                }
                else
                {
                    EventBus.$on('BABYLON.Loaded', fct);
                }
            }
        });
    },

    preventDoubleClick(app)
    {

        app.directive('on-single-click', {
            beforeMount(el, binding)
            {
                let myFunction = binding.value;
                el.addEventListener('click', (event) =>
                {
                    preventDoubleClick(event);
                    myFunction(event);
                });
            }
        });
    },

    /**
     * Alle falschen Seiten auf die Startseite weiterleiten
     *
     * @param data
     */
    redirect404(data)
    {
        data.router.addRoute(
            {
                path: '/404',
                component: PageNotFound
            }
        );
        data.router.addRoute(
            {
                path: '/:catchAll(.*)',
                redirect: '/404',
            }
        );
    },

    /**
     * Seiten-Title setzen.
     *
     * @param data
     */
    pageTitle(data)
    {
        data.router.afterEach((to) =>
        {
            document.title = data.config.meta.title
                + (to.meta.title ? ' - ' + to.meta.title : '');
        });
    }
}
