<template>
    <div class="infobox" v-bind:class="{ 'd-none': !visible }" ref="box">
        <div class="infobox-header" v-on:click="hide">
            <div class="infobox-close"></div>
            <div class="infobox-title hyphens-auto">
                <slot name="title"></slot>
            </div>
            <div class="infobox-subline" v-if="$slots.subline">
                <slot name="subline"></slot>
            </div>
        </div>
        <div class="infobox-body">
            <div class="keyline"></div>
            <div class="overflow-area" ref="content">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { defineProps, ref, inject, nextTick } from 'vue';

    const props = defineProps({
        modalName: String
    });

    let visible = ref(false);

    const eventBus = inject('$eventBus');

    function hide()
    {
        eventBus.$emit('InfoBox.show', false);
    }

    import useDetectCanvasClick from '../tools/useDetectCanvasClick';
    const box = ref();
    useDetectCanvasClick(box, () => {
        hide();
    });

    const tracking = inject('$tracking');
    eventBus.$on('InfoBox.show', (value) =>
    {
        visible.value = (value === props.modalName);

        nextTick(() =>
        {
            const content = ref();
            if (content.value)
            {
                // Scroll Tracking
                content.value.scrollTop = 0;
                tracking.addScroll('InfoBox-' + props.modalName, content);
            }
        });
    });
</script>

<style scoped lang="scss">

</style>
