// preventDoubleClick
export default function (event)
{
    let target = event.target;

    // Element im Button geklickt?
    if (target.tagName !== 'BUTTON')
    {
        target = target.parentElement;
    }

    // Button kurzzeitig deaktivieren
    target.disabled = true;
    setTimeout(() =>
    {
        target.disabled = false;
    }, 500);
}
