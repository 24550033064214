<template>
    <div>
        <div class="backdrop" v-bind:class="{ 'd-none': !visible }"></div>
        <div class="infobox" v-bind:class="{ 'd-none': !visible }" ref="box">
            <div class="infobox-header">
                <div class="infobox-close" v-on:click="visible = false"></div>
                <div class="infobox-title hyphens-auto">
                    Kinderbereich
                </div>
                <div class="infobox-subline">
                    <p>
                        Du bist dabei, den Kinderbereich zu verlassen. Möchtest du das wirklich?
                    </p>

                    <a class="button btn-black" href="#" @click="leaveArea">Ja, den Kinderbereich verlassen</a>
                    <a class="button btn-orange" href="#" @click="stayHere">Nein, hier bleiben</a>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.backdrop {
    content: "";
    position: absolute;
    z-index: map_get($z-layers, backdrop-all);

    top: gridColumnsToRem(-6);
    bottom: gridColumnsToRem(-6);
    width: 100%;

    background: $overlay-help-bg;
}
.infobox {
    z-index: map_get($z-layers, fullscreen);
}
@media screen and (min-width: 800px) {
    .infobox {
        margin-left: auto;
    }
}
.infobox-title {
    font-size: 20px;
}

.infobox-subline {
    font-size: 16px;
}
.child-area-protection {
    background-color: rgb(232, 232, 232);
}
.button {
    display: block;
    border-radius: 16px;
    line-height: 18px;
    padding: 7px 12px;
    font-size: 16px;
    outline-color: rgb(255, 255, 255);
    margin: 5px 0;
}
.btn-black {;
    background-color: $white;
    border: 1px solid $blue-gray;
    color: $blue-gray;
}
.btn-orange {
    background-color: $orange;
    color: rgb(255, 255, 255);
}
</style>

<script setup>
import { ref } from 'vue';
import config from "@/config";

let visible = ref(false);
let targetUrl = ref('');

if (config.branding === 'logo')
{
    document.addEventListener(`click`, e => {
        const link = e.target.closest(`a`);

        if (link && !link.href.startsWith(window.location.origin)
                && !link.href.startsWith('https://www.zdf.de/kinder'))
        {
            e.preventDefault();
            targetUrl.value = link.href;
            visible.value = true;
        }
    });
}

function leaveArea(e)
{
    e.preventDefault();
    visible.value = false;
    window.open(targetUrl.value, '_blank').focus();
}

function stayHere(e)
{
    e.preventDefault();
    visible.value = false;
}

</script>
