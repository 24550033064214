// Quelle: https://github.com/frieupet/scrolltracker/blob/master/scroll.js
export class ScrollTracking
{
    constructor(tracking, label, element)
    {
        this.label = label;
        this.element = element;
        this.tracking = tracking;

        this.percent = 50;

        this.height = this.element.scrollHeight - this.element.clientHeight;
        if (this.height > 0)
        {
            this.nextTracking = this.percent / 100 * this.height;
            this.interval = this.percent / 100 * this.height;
            //console.log('scroll heights', this.element);
            //console.log('scroll heights', this.element.scrollHeight, this.element.clientHeight);
            //console.log('scroll init', this.height, this.nextTracking, this.interval);

            element.addEventListener('scroll', () =>
            {
                this.scroll();
            });
        }
    }

    scroll()
    {
        if (this.element.scrollTop >= this.nextTracking)
        {
            // calculation of % via maxScroll for values equal to interval steps
            this.tracking.scroll(this.label, Math.floor(this.nextTracking / this.height * 10) * 10, this.percent);
            //console.log('scroll tracking', Math.floor( this.nextTracking / this.height * 10 ) * 10);

            // increment maxScroll once with first and then each hit by interval
            this.nextTracking = this.nextTracking + this.interval;
        }
    }
}