<template>
    <div>
        <Fallback>        
            <picture>
                <source media="(orientation: portrait)" srcset="../assets/img/fallback/fallback_P.jpg">
                <source media="(orientation: landscape)" srcset="../assets/img/fallback/fallback_L.jpg">
                <img class="o-fit o-fit--contain" src="../assets/img/fallback/fallback.jpg" alt="">
            </picture>
        </Fallback>
    </div>
</template>


<script>
    export default {
        name: 'model',
        babylonState: {
            camera: {
                position: [2.3776208176647646, 1.330189318541586, 21.34266524853524],
                target: [0, 0, 0],
                //alphaLimits: [null, null],
                betaLimits: [0, 0.5 * Math.PI],
            },
            zdfLogo: {
                House: {
                    roof: {
                        visible: true,
                    }
                }
            }
        }
    }
</script>

<style lang="scss">

</style>
