import BaseObject from "../BaseObject";
import { FadeInOutBehavior } from "@babylonjs/core/Behaviors/Meshes/fadeInOutBehavior";
import hasOwnProperty from "../../tools/hasOwnProperty";
import ForEach from '../../tools/ForEach';

export default class MeshObject extends BaseObject
{
    constructor(scene, name, options, node)
    {
        super(scene, name, options);

        this.children = [];

        this.node = node;

        this.initChilds();
    }

    initChilds()
    {
        this.children = [];
        if (this.node)
        {
            let children = this.node.getChildren();
            if (children)
            {
                for (let i = 0; i < children.length; i++)
                {
                    this.children.push(
                        new MeshObject(this.scene, children[i].id,
                            (this.defaultState.children && this.defaultState.children[children[i].id] ? this.defaultState.children[children[i].id] : {}),
                            children[i]
                        )
                    );
                    // in state anlegen
                    this.defaultState[children[i].id] = {};
                    this.currentState[children[i].id] = {};
                }
            }
        }
    }

    getChildByName(name)
    {
        for (let key in this.children)
        {
            if (hasOwnProperty(this.children, key) && this.children[key].name === name )
            {
                return this.children[key];
            }
        }

        return false;
    }

    runStateFunctions(newState)
    {
        // Unter Objekte anzeigen / verstecken
        // Neu, die Kinder direkt
        ForEach(newState, (child, childName) => {
            let childObject = this.getChildByName(childName);
            if (childObject && this.currentState[childName] !== newState[childName])
            {
                newState[childName] = childObject.setState(newState[childName]);
            }
        }, this);

        // Pickable
        if (this.isChanged(newState, 'pickable'))
        {
            this.setPickable(this.getValue(newState.pickable, true));
        }

        if (this.isChanged(newState, 'animateTexture'))
        {
            if (newState.animateTexture)
            {
                this.startAnimateTexture(newState.animateTexture);
            }
            else
            {
                this.stopAnimateTexture();
            }
        }

        super.runStateFunctions(newState);
    }

    startAnimateTexture(offsetMovment)
    {
        if (!this.animateTextureObserver)
        {
            const textures = this.node.material.getActiveTextures();
            this.animateTextureObserver = this.scene.onBeforeRenderObservable.add(() => {
                textures.forEach((texture) => {
                    texture.uOffset += offsetMovment[0] || 0;
                    texture.vOffset += offsetMovment[1] || 0;
                })
            });
        }
    }
    stopAnimateTexture()
    {
        if (this.animateTextureObserver)
        {
            this.scene.onBeforeRenderObservable.remove(this.animateTextureObserver);
            this.animateTextureObserver = null;
        }
    }

    fade(value)
    {
        if (this.node && this.currentState.fade)
        {
            // Bei Texturen mit Alpha geht das Fade nicht richtig.
            // Als fix wird hier die gleiche Texture auch als opacityTexture benutzt.
            // objects with transparent alpha used for alpha testing cannot have alpha blending enabled
            // Source https://www.html5gamedevs.com/topic/17242-fade-object-with-alpha-texture/?tab=comments#comment-97103
            let albedoTexture = this.node.material.albedoTexture;
            if (albedoTexture && albedoTexture.hasAlpha)
            {
                this.node.material.opacityTexture = this.node.material.albedoTexture;
            }
            // TODO diffuseTexture

            if (!this.fadeBehavior)
            {
                this.fadeBehavior = new FadeInOutBehavior();
                this.fadeBehavior.fadeInTime = 350;
                this.node.addBehavior(this.fadeBehavior);
            }

            this.fadeBehavior.fadeIn(value);
        }
    }

    show()
    {
        super.show();
        this.fade(true);
    }

    hide()
    {
        // Wenn das Objekt fade benutzt verstecken wir es nicht, sonst ist es direkt weg.
        if (!this.currentState.fade)
        {
            super.hide();
        }
        this.fade(false);
    }

    /**
     * Ist das Objekt anklickbar?
     * Bei true wird ein Hotspot durch das Objekt nicht anklickbar, wenn verdeckt.
     * Darum sollte bei transparenten Objekten ggf. pickable: false gesetzt werden.
     *
     * @param value
     */
    setPickable(value)
    {
        if (this.node)
        {
            this.node.isPickable = value;
        }
    }

}