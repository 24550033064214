<template>
    <div class="header">
        <div class="safe-area">
            <ul class="list-inline mb-0 hidden-babylon-loading">
                <li class="list-inline-item" v-if="$config.branding !== 'robotspaceship'">
                    <button type="button" class="btn btn--help" v-on:click="toggleHelp()">
                        <svg class="icon" viewBox="0 0 49.88 80.83" fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linejoin="round" stroke-miterlimit="10">
                            <path d="M16.7,1.6A24.5,24.5,0,0,0,1.6,17.1C-.7,24.6-.6,25,3.9,25c3.5,0,3.9-.3,4.5-3.1A17.6,17.6,0,0,1,25.2,8C33.6,8,42,16.4,42,24.8A17.52,17.52,0,0,1,27.3,41.7c-5.9.9-6.3,1.6-6.3,11.4V62h7.9l.3-6.2.3-6.1,4.4-1.5C42.6,45.3,50,34.6,50,25,50,14.8,42.4,4.4,32.9,1.6,26.5-.3,23-.3,16.7,1.6Z" transform="translate(-0.12 -0.17)"/>
                            <path d="M21,76v5h8V71H21Z" transform="translate(-0.12 -0.17)"/>
                        </svg>
                        <span class="help-text help-text--help">
                          <img src="../assets/img/hilfe_handschrift_anim.png" alt="Hilfe">
                        </span>
                    </button>
                </li>
                <li class="list-inline-item" v-if="showAudioButton">
                    <button ref="audioButton" type="button" class="btn btn--audio" v-on:click="toggleAudio()">
                        <svg class="icon" viewBox="0 0 78.9 81.9" fill="none" stroke="currentColor" stroke-width="9" stroke-linejoin="round" stroke-miterlimit="10">
                            <g>
                                <rect x="4.5" y="22.7" width="16.6" height="36.5"/>
                                <polygon points="45.9,4.5 21.1,22.7 21.1,59.2 45.9,77.4"/>
                            </g>
                            <path class="icon__status" d="M56.1,22.7c10.1,0,18.2,8.2,18.2,18.2s-8.2,18.2-18.2,18.2"/>
                        </svg>
                        <span class="help-text help-text--audio">
                          <img src="../assets/img/ton_handschrift_anim.png" alt="Ton EIN/AUS">
                        </span>
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ZdfHeader",
        inject: ['$config'],
        data: () =>
        {
            return {
                audioAvailable: false,
                helpOpen: false,
            };
        },
        created()
        {
            this.$eventBus.$on('showAudio', () =>
            {
                this.audioAvailable = true;
            });
            this.$eventBus.$on('currentPage', () =>
            {
                if (!this.$audioController.config.background)
                {
                    this.audioAvailable = false;
                }
                this.helpOpen = false;
            });
            this.$eventBus.$on('help.toggle', () =>
            {
                this.helpOpen = !this.helpOpen;
            });
        },
        computed: {
            showAudioButton: function ()
            {
                return this.audioAvailable || (this.helpOpen && this.$audioController.isEnabled());
            }
        },
        methods: {
            toggleHelp()
            {
                this.$tracking.actionClick('Hilfe auf/zu');
                this.$eventBus.$emit('help.toggle');
            },
            toggleAudio()
            {
                this.$tracking.actionClick('Ton an/aus');
                this.$eventBus.$emit('audio.toggle');
            },
        }
    };
</script>

<style scoped lang="scss">
.list-inline-item {
    pointer-events: auto;
}
</style>
