export default function (defaultConfig, newConfig)
{

    // Babylon Config und Standardwerte zusammenführen
    // Doku: https://github.com/TehShrike/deepmerge
    const merge = require('deepmerge');
    // Objekte werden gemerged, aber arrays werden überschrieben
    const isNotArray = (item) =>
    {
        return typeof item === 'object' && !Array.isArray(item);
    };

    return merge.all([{}, defaultConfig, newConfig], {isMergeableObject: isNotArray});
}