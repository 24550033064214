<template>
    <div class="fullscreen-image" v-on:click="openFullscreenComparison()">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "FullscreenImage2",
        props: [
            'src1',
            'src2',
            'value'
        ],
        methods: {
            openFullscreenComparison()
            {
                this.$eventBus.$emit('FullscreenComparison.show', this.src1, this.src2, this.value);
            },
        }
    }
</script>

<style scoped lang="scss">

    .zdf-comparison-slider {

        --default-handle-width: 80px;
    }

</style>
