<template>
    <div v-bind:class="{'invisible': !show }">
        <div class="btn btn--start" id="progress">
            <svg viewBox="0 0 70 70">
                <circle v-bind:style="progress" r="30" cx="35" cy="35"></circle>
            </svg>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LoadingProgress",
        data: function ()
        {
            return {
                show: false,
                finished: false,
                items: []
            }
        },
        computed: {
            loaded: function ()
            {
                return this.items.reduce((accumulator, item) => {
                    return accumulator + item.loaded;
                }, 0);
            },
            total: function ()
            {
                return this.items.reduce((accumulator, item) => {
                    return accumulator + item.total;
                }, 0);
            },
            progress: function ()
            {
                if (this.total > 0)
                {
                  let u = 2 * Math.PI * 30;
                  let calc = u - (u * this.loaded / this.total);
                  return `stroke-dashoffset: ${calc}`;
                  // return this.loaded / this.total * 100;
                }

                return 0;
            },
            // progressFormatted: function ()
            // {
            //     return Math.round(this.progress) + ' %';
            // }
        },
        created()
        {
            this.$eventBus.$on('LoadingProgress', (loadingProgressEvent) =>
            {
                // Für den Google Bot zeigen wir direkt den Weiter-Button an
                if (window.navigator.userAgent.indexOf('Googlebot') !== -1)
                {
                    this.show = true;
                    this.finished = true;
                    return;
                }

                this.show = true;
                let key = this.items.findIndex((item) => item.filename === loadingProgressEvent.filename);
                if (key > -1)
                {
                    // update
                    if (loadingProgressEvent.finished)
                    {
                        this.items[key].loaded = this.items[key].total;
                    }
                    else
                    {
                        this.items[key].loaded = loadingProgressEvent.event.loaded;
                    }
                }
                else
                {
                    // add
                    this.items.push({
                        filename: loadingProgressEvent.filename,
                        loaded: loadingProgressEvent.event.loaded,
                        total: loadingProgressEvent.event.total,
                    });
                }

                // Fertig geladen?
                if (this.total > 0 && this.loaded === this.total && !this.finished)
                {
                    this.finished = true;
                    this.$tracking.loadingTime();
                }
            });
        }
    }
</script>

<style scoped lang="scss">

    #progress {
        background: rgba(0, 0, 0, 0.5);
        border-radius: 50%;

        svg {
            width: 100%;
            height: 100%;
            transform: scale(-1,1) rotate(-90deg);
            position: relative;
            z-index: 100;

            circle {
                stroke-dasharray: 2 * 3.1415 * 30px;
                stroke-dashoffset: 2 * 3.1415 * 30px;
                stroke-linecap: round;
                stroke-width: 3px;
                stroke: white;
                fill: none;
            }
        }
    }

</style>
