import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import BabylonStory from "./BabylonStory/BabylonStory";
const config = require('./config');

const app = createApp(App);
app.use(router)
    .use(BabylonStory, {config, router})
    .mount('#app')
