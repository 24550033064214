<template>
    <div ref="ZDFPlayerInstance" class="b-zdfplayer zdfplayer_ zdfplayer_core/Player" v-bind:data-jsb="jsb"></div>
</template>

<script>
    // Doku zum Abspielen von Medien, die nicht aus der Mediathek kommen
    // http://online-guidelines.zdf.de/videoplayer/manuelle-assets/

    import EventBus from "@/BabylonStory/tools/event-bus";

    function initPlayer()
    {
        if (window.zdfplayer)
        {
            window.zdfplayer.applyBehaviour(window.document);

            window.zdfplayer.on('Player::PAUSED', function ()
            {
                //console.log('zdfplayer.PAUSED');
                EventBus.$emit('video.stopped');
            });
            window.zdfplayer.on('Player::PLAYING', () =>
            {
                //console.log('zdfplayer.PLAYING');
                EventBus.$emit('video.playing');
            });
            window.zdfplayer.on('Player::STOPPED', () =>
            {
                //console.log('zdfplayer.STOPPED');
                EventBus.$emit('video.stopped');
            });
            window.zdfplayer.on('Player::FINISHED', () =>
            {
                //console.log('zdfplayer.FINISHED');
                EventBus.$emit('video.stopped');
            });
        }
    }

    export default {
        name: "ZDFPlayer",
        props: ['modalName', 'video', 'data-jsb'],
        data: function ()
        {
            return {
                jsb: '',
            };
        },
        created()
        {
            this.setTracking();
        },
        mounted()
        {
            // ZDF Video Player einfügen und initialisieren
            if (!document.getElementById('zdfplayer_script'))
            {
                this.addPlayerCSS();
                this.addPlayerJS();
            }
            else
            {
                // schon vorhanden, nur initialisieren
                initPlayer();
            }

            this.pauseOnInfoClose();
        },
        methods: {
            setTracking: function()
            {
                const dataJsb = JSON.parse(this.dataJsb);
                if (!window.zdfcmp.hasTrackingConsent())
                { 
                    dataJsb.noTracking = true;
                }
                this.jsb = JSON.stringify(dataJsb);
            },
            addPlayerJS: function()
            {
                // Script <script src="https://www.zdf.de/ZDFplayer/latest-v2/skins/zdf/zdf-player.js" type="text/javascript"...
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.id = "zdfplayer_script";
                script.src = "https://ngp.zdf.de/latest-v3/skins/zdf/zdf-player.js";
                script.onload = () =>
                {
                    initPlayer();
                };
                document.head.appendChild(script);
            },
            addPlayerCSS: function()
            {
                // CSS <link rel="stylesheet" href="https://www.zdf.de/ZDFplayer/latest-v2/skins/zdf/zdf-player.css" type="text/css">
                var css = document.createElement("link");
                css.type = "text/css";
                css.rel = "stylesheet";
                css.href = "https://ngp.zdf.de/latest-v3/skins/zdf/zdf-player.css";
                document.head.appendChild(css);
            },
            pauseOnInfoClose: function()
            {
                // Wenn die InfoBox geschlossen wird, pausieren wir den Player
                this.$eventBus.$on('InfoBox.show', (value) =>
                {
                    if (value === false && window.zdfplayer && this.$refs.ZDFPlayerInstance)
                    {
                        let ZDFPlayerInstanceID = this.$refs.ZDFPlayerInstance.id;
                        window.zdfplayer.fireEvent('Player::PAUSE', { id: ZDFPlayerInstanceID });
                    }
                });
            }
        }
    }
</script>

<style scoped lang="scss">

</style>
