<template>
    <div class="textbanner" v-on:click="toggle()" v-if="visible">
        <div class="keyline"></div>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "TextBanner",
        data: function ()
        {
            return {
                visible: true,
            };
        },
        methods: {
            toggle: function ()
            {
                this.$el.classList.toggle('is-hidden');
            }
        },
        created()
        {
            this.$eventBus.$on('InfoBox.show', (value) =>
            {
                this.visible = (value === false);
            });
        }
    };
</script>

<style scoped lang="scss">

    @import "../../BabylonStory/assets/scss/components/textbanner";

</style>
