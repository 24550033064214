import hasOwnProperty from "./hasOwnProperty";

export default {
    getQueryAll()
    {
        let vars = {};
        window.location.href.replace(/[?&]+([^=&]+)=([^&#]*)/gi, function (m, key, value)
        {
            vars[key] = value;
        });
        return vars;
    },
    hasQuery(parameter)
    {
        let query = this.getQueryAll();
        return hasOwnProperty(query, parameter);
    },
    getQuery(parameter, default_value)
    {
        let url_parameter = default_value;
        let query = this.getQueryAll();
        if (hasOwnProperty(query, parameter))
        {
            url_parameter = query[parameter];
        }
        return url_parameter;
    }
}