<template>
    <img v-bind:src="getApng(useApng, apng, png)">
</template>

<script>
    const modernizr = require("modernizr");

    export default {
        name: "Apng",
        props: [
            'apng', 'png'
        ],
        data: function ()
        {
            return {
                useApng: false,
            }
        },
        mounted()
        {
            modernizr.on('apng', () =>
            {
                this.useApng = modernizr.apng;
            });
        },
        methods: {
            getApng: function (useApng, apng, png)
            {
                // use apng or fallback image
                return (useApng ? apng : png);
            },
        },
    }
</script>

<style scoped lang="scss">

</style>
