
export default class TimerManager
{
    constructor()
    {
        this.timers = [];
    }

    /**
     * Timer wird beim Wechsel der Seite abgebrochen.
     *
     * @param time Wartezeit in Millisekunden.
     * @param handler Die Funktion, die nach `time` Millisekunden ausgeführt werden soll.
     */
    add(time, handler)
    {
        let timer = {
            id: window.setTimeout(handler, time)
        }

        this.timers.push(timer);
    }

    /**
     * Alle Timer abbrechen
     */
    reset()
    {
        let timerLength = this.timers.length;
        for (let i = 0; i < timerLength; i++)
        {
            window.clearTimeout(this.timers[i].id);
        }
        // Alle Timer IDs löschen
        this.timers = [];
    }
}