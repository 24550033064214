import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { MeshBuilder } from "@babylonjs/core/Meshes/meshBuilder";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { Color3 } from "@babylonjs/core/Maths/math.color";
import { DynamicTexture } from "@babylonjs/core/Materials/Textures/dynamicTexture";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import BaseObject from "../BaseObject";

export default class Label extends BaseObject
{
    constructor(scene, name, options)
    {
        super(scene, name, options);

        // Dynamic Textures Doku: https://doc.babylonjs.com/how_to/dynamictexture

        //Set font
        let font_size = 48;
        let font_type = "Arial";
        //let font = "bold " + font_size + "px " + font_type;
        let font = "normal " + font_size + "px " + font_type;

        //Set height for plane
        let planeHeight = 4;

        //Set height for dynamic texture
        let DTHeight = 1.5 * font_size; //or set as wished

        //Calcultae ratio
        let ratio = planeHeight / DTHeight;

        //Use a temporay dynamic texture to calculate the length of the text on the dynamic texture canvas
        let temp = new DynamicTexture(name + "_temp", 64, scene);
        let tmpctx = temp.getContext();
        tmpctx.font = font;
        let DTWidth = tmpctx.measureText(options.text).width + 8;
        temp.dispose();

        //Calculate width the plane has to be
        let planeWidth = DTWidth * ratio;

        //Create plane
        let writingPlane = MeshBuilder.CreatePlane(name, {width: planeWidth, height: planeHeight}, scene);
        writingPlane.position = new Vector3(...options.position);
        writingPlane.billboardMode = Mesh.BILLBOARDMODE_ALL;

        //Create dynamic texture
        let dynamicTexture = new DynamicTexture(name + "_text", {
            width: DTWidth,
            height: DTHeight
        }, scene, false);

        //Draw text
        dynamicTexture.drawText(options.text, null, null, font, "#FFFFFF", "transparent", true, 3);

        //create material
        let mat = new StandardMaterial(name + "_mat", scene);
        mat.diffuseTexture = dynamicTexture;
        mat.diffuseTexture.hasAlpha = true;
        mat.emissiveColor = new Color3(255 / 255, 255 / 255, 255 / 255);

        //apply material
        writingPlane.material = mat;
        writingPlane.setEnabled(options.visible);
        this.node = writingPlane;
    }
}
