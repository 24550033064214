<template>
    <div v-on:click="close()" class="help">
        <div class="help-items">
            <div class="help-item">
                <div class="help-item-col">
                    <div class="help-item-header hyphens-auto">Bedienung</div>
                    <div>Drücken, um zum nächsten oder vorherigen Storypunkt zu gehen.</div>
                </div>
                <div class="help-item-col">
                    <div class="embed-responsive">
                        <!--<apng class="embed-responsive-item help-item-img"  v-bind:apng="require('../assets/img/ui_bedienung-tiny_anim.png')" v-bind:png="require('../assets/img/ui_bedienung_0050.png')" alt="" />-->
                        <apng class="embed-responsive-item help-item-img"
                              v-bind:apng="require('../assets/img/ui/bedienungCrop-256--tiny.png')"
                              v-bind:png="require('../assets/img/ui/ui_bedienung_0050.png')" alt=""/>
                    </div>
                </div>
            </div>
            <div class="help-item" v-if="visible">
                <div class="help-item-col">
                    <div class="help-item-header hyphens-auto">Zoomen</div>
                    <div>Aufziehen, um die Entfernung zum Objekt zu ändern.</div>
                </div>
                <div class="help-item-col">
                    <div class="embed-responsive">
                        <!--<apng class="embed-responsive-item help-item-img"  v-bind:apng="require('../assets/img/ui_zoomen-tiny_anim.png')" v-bind:png="require('../assets/img/ui_zoomen_0105.png')" alt="" />-->
                        <apng class="embed-responsive-item help-item-img"
                              v-bind:apng="require('../assets/img/ui/zoomCrop-256--tiny.png')"
                              v-bind:png="require('../assets/img/ui/ui_zoomen_0105.png')" alt=""/>
                    </div>
                </div>
            </div>
            <div class="help-item" v-if="visible">
                <div class="help-item-col">
                    <div class="help-item-header hyphens-auto">Rotation</div>
                    <div>Wischen, um das Objekt zu drehen.</div>
                </div>
                <div class="help-item-col">
                    <div class="embed-responsive">
                        <!--<apng class="embed-responsive-item help-item-img"  v-bind:apng="require('../assets/img/ui_rotation-tiny_anim.png')" v-bind:png="require('../assets/img/ui_rotation_0030.png')" alt="" />-->
                        <apng class="embed-responsive-item help-item-img"
                              v-bind:apng="require('../assets/img/ui/rotationCrop-256--tiny.png')"
                              v-bind:png="require('../assets/img/ui/ui_rotation_0030.png')" alt=""/>
                    </div>
                </div>
            </div>
            <div class="help-item">
                <div class="help-item-col">
                    <div class="help-item-header hyphens-auto">Hotspot</div>
                    <div>Drücken, um weitere Infos zu erhalten.</div>
                </div>
                <div class="help-item-col">
                    <div class="embed-responsive">
                        <!--<apng class="embed-responsive-item help-item-img"  v-bind:apng="require('../assets/img/ui_hotspot-tiny_anim.png')" v-bind:png="require('../assets/img/ui_hotspot_0060.png')" alt="" />-->
                        <apng class="embed-responsive-item help-item-img"
                              v-bind:apng="require('../assets/img/ui/hotspotCrop-256--tiny.png')"
                              v-bind:png="require('../assets/img/ui/hotspot_fallback.png')" alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Help",
        data: function ()
        {
            return {
                visible: !window.BABYLON_FALLBACK,
            };
        },
        methods: {
            close()
            {
                this.$tracking.actionClick('Hilfe zu');
                this.$eventBus.$emit('help.toggle');
            }
        }
    };
</script>


<style scoped lang="scss">

</style>
